import React from 'react'
import { Icon } from 'components/utils'
function TileBox({ svg, text, disable }) {
  return (
    <>
      <div className="">
        <div className="p-2">
          <div
            className={`${
              disable
                ? 'bg-grey-darker'
                : 'cursor-pointer bg-gradient-green-45deg'
            } shadow-md h-12 aspect-ratio-square rounded-xl relative overflow-hidden`}
          >
            <div className="absolute flex flex-col items-center content-between justify-center morri-tile pin-y pin-x text-orange">
              <Icon svg={svg} size="180" color="text-orange" />
            </div>
          </div>
        </div>
        {text && (
          <span className="block px-2 py-2 font-light leading-tight text-center text-black lg:py-4 mob:text-sm">
            {text}
          </span>
        )}
      </div>
    </>
  )
}

export default TileBox
