import React from 'react'
import TileBox from './TileBox'

const TileWithExternalLink = ({ svg, text, url, disable, onClick }) => {
  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="w-full no-underline"
      onClick={onClick}
    >
      <TileBox svg={svg} text={text} disable={disable} />
    </a>
  )
}

export default TileWithExternalLink
