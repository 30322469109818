import React, { useEffect } from 'react'
import ReactGA from 'react-ga4'
import { useLocation } from 'react-router-dom'
import { contentfulClient, useSetState, useQueryString, Core } from '../util'
import { useNewsState } from 'store/news-context'
import { useSitesState } from 'store/sites-context'
import { useUserState } from 'store/user-context';
import { TheHeader, HeaderTab } from 'components/header'
import { Search } from 'components/search'
import { NewsDeck } from 'components/news'
import { Container, SubSection, Section } from 'components/utils'
import { SiteSearchResults } from '../components/sites'
import { LinerProgressBar } from '../components/sites/LinerProgressBar'
import { SEARCH_LIMIT } from 'constant/common'
import BulletinSection from 'components/retail-comms/SearchSection'

const contentTypesToExcludeFromSitesSearchResults = [
  'article',
  'mainNavigation',
  'category',
]

const SearchList = () => {
  const defaultState = {
    isLoading: false,
    newsList: undefined,
    sitesSearchList: undefined,
    bulletinList: undefined,
  }
  const { fetching, setHighlightString } = useSitesState()
  const userData = useUserState();

  const [state, setState] = useSetState(defaultState)
  const { redirectOnError } = Core
  const newsState = useNewsState()
  const { pathname } = useLocation()
  const query = useQueryString()
  const urlParams = query.get('q')
  const includeBulletins = !!query.get('b');

  useEffect(
    () => {
      ReactGA.send({ hitType: "pageview", page: pathname, title: "Search" })
    },
    [pathname]
  )

  useEffect(
    () => {
      const fetchSearchResult = searchString => {
        setState({
          isLoading: true,
        })

        const contentfulCalls = [];

        contentfulCalls.push(contentfulClient
          .getContentfulClient()
          .getEntries({
            content_type: 'article',
            order: '-fields.publishAt',
            'fields.title[match]': searchString,
            'fields.publishAt[lte]': (new Date()).toISOString()
          }));

        contentfulCalls.push(contentfulClient
          .getContentfulClient()
          .getEntries({
            query: searchString,
            order: '-sys.createdAt',
          }));

        if (includeBulletins) {
          contentfulCalls.push(contentfulClient
            .getReatilCommsContentfulClient()
            .getEntries({
              content_type: 'bulletin',
              query: searchString,
              'fields.bulletinType[exists]': "true",
              'fields.location[in]': `${userData.current_user.store_id},ALL`,
              order: '-fields.date',
              select: 'fields.bulletinType,fields.date',
              limit: 8
            }).then(results => results.items.reduce((acc, cur) => {
                acc[cur.sys.id] = {...cur.fields, id: cur.sys.id};
                return acc;
              }, {})
            ));

          contentfulCalls.push(contentfulClient
            .getReatilCommsContentfulClient()
            .getEntries({
              content_type: 'notice',
              query: searchString,
              select: 'sys.id',
              order: '-sys.updatedAt',
              limit: 8
            }).then(async results => {
              let allNoticeBulletins = {};
              if (results.total > 0) {
                for (let i = 0; i < results.items.length; i++){
                  let noticeBulletins = await contentfulClient
                    .getReatilCommsContentfulClient()
                    .getEntries({
                      content_type: 'bulletin',
                      'links_to_entry': results.items[i].sys.id,
                      'fields.bulletinType[exists]': "true",
                      'fields.location[in]': `${userData.current_user.store_id},ALL`,
                      order: '-fields.date',
                      select: 'fields.bulletinType,fields.date',
                      limit: 8
                    }).then(results => {
                      return results.items.reduce((acc, cur) => {
                          acc[cur.sys.id] = {...cur.fields, id: cur.sys.id};
                          return acc;
                        }, {});
                    });
                  allNoticeBulletins = {
                    ...allNoticeBulletins,
                    ...noticeBulletins
                  }
                }
              }
              return allNoticeBulletins;
            }));
        }

        Promise.all(contentfulCalls)
          .then(results => {
            let searchList = []
            const titleQueryResultIds = results[0].items.map(
              item => item.sys.id
            )
            const articles = (results[1].items || []).filter(
              item => item.sys.contentType.sys.id === 'article'
            )

            const sitesSearchList = (results[1].items || []).filter(
              item =>
                contentTypesToExcludeFromSitesSearchResults.indexOf(
                  item.sys.contentType.sys.id
                ) === -1
            )
            searchList = searchList.concat(results[0].items)
            searchList = searchList.concat(
              articles.filter(
                item => titleQueryResultIds.indexOf(item.sys.id) === -1
              )
            )

            const bulletinList = Object.values({...results[2], ...results[3]});
            bulletinList.sort((a,b) => new Date(b.date) - new Date(a.date));
            if (bulletinList.length > 8) {
              bulletinList.length = 8;
            }

            setState({
              newsList: searchList,
              bulletinList,
              sitesSearchList,
              isLoading: false,
            })
          })
          .catch(error => {
            redirectOnError(error)
            return
          })
      }
      urlParams !== null && fetchSearchResult(urlParams)
      setHighlightString(urlParams)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [urlParams]
  )

  function hideLoader() {
    setState({ isLoading: false })
  }

  const { sitesSearchList, newsList, bulletinList, isLoading } = state

  const newsItems =
    newsList?.length && newsList.length > 0
      ? newsList.slice(0, SEARCH_LIMIT)
      : null

  const bulletinItems =
    bulletinList?.length && bulletinList.length > 0
      ? bulletinList.slice(0, SEARCH_LIMIT)
      : null

  const sitesSearchItems =
    sitesSearchList?.length && sitesSearchList.length > 0
      ? sitesSearchList
      : null

  const transitionEffect = newsState.isNewsBarActive ? 'transition-leave' : ''

  return (
    <div>
      {fetching && <LinerProgressBar siteName="" />}
      <div className={`list-page  ${transitionEffect}`}>
        <HeaderTab activeTab="mymorri" />
        <TheHeader />
        <section className="py-1 bg-grey-transparent">
          <div className="container mx-auto md:px-8">
            <Search placeholder="Search our sites" value={urlParams} />
          </div>
        </section>
        <div className="py-0 pb-6 mb-6 sm:pt-6 sm:pb-10 bg-grey-transparent">
          <Container>
            <h1 className="text-2xl font-semibold text-body md:text-3xl">
              Search Results
            </h1>
          </Container>
        </div>
        <Container>
          <Section>
            {sitesSearchItems && (
              <SiteSearchResults
                searchResults={sitesSearchList}
                onParseComplete={hideLoader}
                isLoading={isLoading}
              />
            )}
            {newsItems && (
              <SubSection title="News">
                <NewsDeck newsItems={newsItems} />
              </SubSection>
            )}
            {bulletinItems && (
              <SubSection title="Bulletins">
                <BulletinSection bulletinItems={bulletinItems} />
              </SubSection>
            )}
            {isLoading && <LinerProgressBar siteName="" />}
            {!isLoading &&
              !sitesSearchItems &&
              !newsItems &&
              !bulletinItems && (
                <div className="flex items-center justify-center my-10 text-lg font-semibold text-primary">
                  No search results found
                </div>
              )}
          </Section>
        </Container>
      </div>
    </div>
  )
}

export default SearchList
