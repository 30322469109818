export const accessGroup = {
  commercial: [
    'ggg_commercial_central_colleagues',
    'man-mymorri-commercialhomepage-ug',
  ],
  retailComms: [
    'ggg_store_colleagues',
    'ggg_central_colleagues'
  ]
}
