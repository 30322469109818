import React from 'react'
import { Link } from 'react-router-dom'
import TileBox from './TileBox'

const TileWithInternalLink = ({ svg, text, url, disable, onClick }) => {
  return (
    <Link to={url} className="w-full no-underline" onClick={onClick}>
      <TileBox svg={svg} text={text} disable={disable} />
    </Link>
  )
}

export default TileWithInternalLink
