import React, { useState, useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { TheHeader, HeaderTab } from '../components/header'
import { PageDetails, PageMenu } from '../components/sites'
import RetailTabs from '../pages/retail-comms/Tabs';
import { SearchContainer } from '../components/search'
import { ErrorBoundary } from 'components/error'
import { useNewsState, useNewsDispatch } from 'store/news-context'
import { useSitesState } from 'store/sites-context'
import { PageContextProvider } from '../store/page-context'
import ReactGA from 'react-ga4'

const Pages = () => {
  const params = useParams()
  const { setHighlightString, pageNavigation, siteMap } = useSitesState()
  const newsState = useNewsState()
  const { hideSearchContainer } = useNewsDispatch()
  const [pageList, setPageList] = useState()
  const { siteName, rootPage } = params
  
   //get page id from url
  const { pathname } = useLocation()
 
  useEffect(
    () => {
      if (siteName !== 'retail-comms' && Array.isArray(pageNavigation)) {
        const page = pageNavigation.find(nav => {
          return nav.href.slug === siteName
        })
        page ? setPageList(page.children) : setPageList([])
      }
      setHighlightString('')
      hideSearchContainer()
      document.body.classList.remove('handheld:overflow-hidden')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageNavigation, siteName]
  )

 
  useEffect(() => { 
      if(pathname){
        ReactGA.send({ hitType: "pageview", page: pathname, title: siteName })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname]
  )
  
  const pageInfo = siteMap && siteMap[pathname]
  const pageId = pageInfo?.id

  return siteName ? (
    <>
      <HeaderTab activeTab={siteName} />
      <TheHeader />
      <section className="bg-grey-transparent">
        <div className="container mx-auto md:px-8">
          <SearchContainer isActive={newsState.isSearchActive} />
        </div>
      </section>

      <ErrorBoundary>
        <PageContextProvider
          value={{
            path: pathname,
            siteName,
            newMenuItems: pageList,
          }}
        >
          {siteName === 'retail-comms'
            ? <RetailTabs />
            : <>
              {pageList && <PageMenu data={pageList} activePage={rootPage} />}
              {pageId && <PageDetails rootPage={rootPage} pageId={pageId} />}
            </>
          }
        </PageContextProvider>
      </ErrorBoundary>
    </>
  ) : null
}

export default Pages
