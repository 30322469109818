export default {
  local: {
    epayslips_url: 'https://acn-epayslips.appspot.com',
    activity_planner_url: 'https://acn-activity-planner.appspot.com/quarter',
    user_provisioning_url: 'https://morrisons-user-provisioning.appspot.com',
    // client_id:
    //   '858113026121-ost1pr84avr3tlnroahnaiidpr369mq9.apps.googleusercontent.com',
    client_id:
      '877614078106-ap72ofvi4b7d8rc6f3ad6rd6j01d22k2.apps.googleusercontent.com',
    email_domain: 'test.morrisonsplc.co.uk',
    stub_oauth: true,
    leavers_group: 'morrisons.leavers@test.morrisonsplc.co.uk',
    payslips_group: 'morrisons.payslips@test.morrisonsplc.co.uk',
    thinkShrink_group: 'man-mymorri-shrink-tile-ug@test.morrisonsplc.co.uk',
    salaried_group: 'ggg_salaried_colleagues@test.morrisonsplc.co.uk',
    hourly_group: 'ggg_hourly_colleagues@test.morrisonsplc.co.uk',
    store_group: 'ggg_store_colleagues@test.morrisonsplc.co.uk',
    central_group: 'ggg_central_colleagues@test.morrisonsplc.co.uk',
    // added central_hilmore & central_field groups as part of Jira id :MYM-48
    central_hilmore_group:
      'ggg_hilmore_central_colleagues@test.morrisonsplc.co.uk',
    central_field_group: 'ggg_field_central_colleagues@test.morrisonsplc.co.uk',
    activityPlanner_super_admin_group:
      'activity.planner.admin.super@test.morrisonsplc.co.uk',
    skipton_colleague_group: 'ggg_056_colleagues@test.morrisonsplc.co.uk',
    performSalaried:
      'https://sites.google.com/test.morrisonsplc.co.uk/myperformance/salary-colleagues',
    performHourly:
      'https://sites.google.com/test.morrisonsplc.co.uk/myperformance/hourly-colleagues',
    performGeneric:
      'https://sites.google.com/test.morrisonsplc.co.uk/myperformance/home',
    reflexisLink: 'https://morrisons.reflexisinc.co.uk/RWS4',
    holidaysApp:
      'https://sites.google.com/a/morrisonsplc.co.uk/morrison-news/my-rotas-and-my-holidays',
    handbookDefaultLink:
      'https://sites.google.com/morrisonsplc.co.uk/myhandbook-retail/home',

    holidaysAppLink:
      'https://script.google.com/a/test.morrisonsplc.co.uk/macros/s/AKfycbweMN4avh3CywTCOVJxVwO1yojB5cgw1VLbReRDzYnDQlI6JIk/exec',
    communitiesLink: 'http://chat.google.com/',
    leavers_questionnaire:
      'https://docs.google.com/forms/d/e/1FAIpQLSfhGZCj0vBioUtH_Q2TxGun4lnC_1gBoIxu0SZUqwQ1DuBfaQ/viewform?usp=sf_link',
    dataProtectionLink:
      'https://sites.google.com/morrisonsplc.co.uk/data-protection-2019/home',

    colleagueDiscountManagementLink:
      'https://pepl-colleaguediscountsys-dev.web.app/home',
    colleagueDiscountRegistrationLink:
      'https://pepl-colleaguediscount-dev-fb.firebaseapp.com',

    google_analytics_id: 'G-NJ1T4L1CJW',
    contentfullApi: {
      // space: 'fwof4kybqy1w',
      // environment: 'master',
      // accessToken:
      //   'f3954e6344584819dc911f841ba9bb7daba1909c87eb9eddbab12a1bc8ea51de',
      // host: 'preview.contentful.com',

      space: 'fwof4kybqy1w', //'fwof4kybqy1w',
      environment: 'test',
      accessToken: 'MYMORRI_CONTENTFUL_ACCESS_TOKEN_LOCAL',
    },
    retailCommsContentfulApi: {
      space: 'lzjg12b015yu',
      environment: 'test',
      accessToken: 'RETAILCOMMS_CONTENTFUL_ACCESS_TOKEN_LOCAL',
    },
    archive: {
      lastFinancialYear: {
        year: '2020-2021',
        startWeek: '2020-02-03T00:00:00.000Z',
        endWeek: '2021-01-31T06:08:04.000Z',
      },
      years: [
        {
          year: '2021/2022',
          startWeek: '2021-02-01T00:00:00.000Z',
          endWeek: '2022-01-30T06:08:04.000Z',
        },
        {
          year: '2020/2021',
          startWeek: '2020-02-03T00:00:00.000Z',
          endWeek: '2021-01-31T06:08:04.000Z',
        },
        {
          year: '2019/2020',
          startWeek: '2019-02-04T00:00:00.000Z',
          endWeek: '2020-02-02T06:08:04.000Z',
        },
      ],
    },
    twoStepLink: 'https://g.co/2sv',
    handbookLinkRetailNPS:
      'https://sites.google.com/morrisonsplc.co.uk/my-handbook-retail-2-nps',
    handbook: [
      {
        group: 'ggg_store_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/my-handbook-retail-2-nps/home',
      },
      {
        group: 'ggg_manufacturing_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/myhandbook-manufacturing/home',
      },
      {
        group: 'ggg_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/myhandbook-hilmorehouse/home',
      },
      {
        group: 'ggg_swanvalley_distribution_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/my-handbook-swanvalley/home',
      },
      {
        group: 'ggg_stockton_distribution_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/myhandbook-stockton/home',
      },
      {
        group: 'ggg_sttngbrne_distribution_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/my-handbook-sittingbourne/home',
      },
      {
        group: 'ggg_latimer_distribution_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/my-handbook-latimerpark-corby/home',
      },
      {
        group: 'ggg_corby_distribution_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/my-handbook-latimerpark-corby/home',
      },
      {
        group: 'ggg_j41_distribution_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/myhandbook-wakefieldj41/home',
      },
      {
        group: 'ggg_gadbrook_distribution_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/my-handbook-gadbrook/home',
      },
      {
        group: 'ggg_gadbrookl_distribution_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/my-handbook-gadbrook/home',
      },
      {
        group: 'morrisons.leavers@test.morrisonsplc.co.uk',
        link:
          'https://pepl-leavershb-spt-fb.firebaseapp.com',
      },
      {
        group: 'ggg_bridgwater_distribution_colleagues@test.morrisonsplc.co.uk',
        link: 'https://sites.google.com/morrisonsplc.co.uk/willowgreen1/home',
      },
    ],
    workingPattern: [
      {
        group: 'ggg_people_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1t6caE7MJJ7W1prCOS3G3U_mQwrxy_EQOwAfm28TsB8o',
      },
      {
        group: 'ggg_technology_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1qqMHXhWI6ovRXYipHWzqLQfTN95e_rg5Ixe8aN_2rUA/edit?usp=sharing',
      },
      {
        group:
          'man-myworkingpattern-centrallogistics-ug@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1WAAqJQ_y0NOxujFfkPHjkivzsUv-OBhKoO-yEyyOiuM',
      },
      {
        group:
          'man-my-working-pattern---central-manufacturing-ug@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1Ob5qf2uwQTiYRzfkfaBZ0JurowCvnY0cDJbnciiGFjo',
      },
      {
        group: 'ggg_retail_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1YjtfekcoHH2EvmnfhVh7zuxy1HBxAT3L0FnSNu-8Kt8',
      },
      {
        group: 'ggg_loss_prevention_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1tk7fYzNntSz-BfwKebuh3oSaT0qxZ2LIYeifpa1zCtE',
      },
      {
        group: 'ggg_commercial_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1Ez255TEvyBgO5vhQ3kGYX1vRpoN37zXbggXs0vJSF3Y',
      },
      {
        group: 'ggg_marketing_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1FgxSG1lhJZZyOJz8BTVuAuKbySxyI9Z7MXidOM3kQ_8',
      },
      {
        group:
          'man-my-working-pattern-contact-centre-ug@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1TNhD6iSEuLP0x0Y6FCUoXJaX9H-Uj6g5cinUWUGBpZU',
      },
      {
        group:
          'ggg_corporate_services_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1W4O8zFxL3VIrMMzUIj0SixROrdPfHuxaETWo0RHP4lU',
      },
      {
        group: 'man-my-working-pattern-directorate-ug@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1HKltsFQjUWuOj8VneVDGRDJZ8l2iVDfcEoPLJpY7yuc',
      },
      {
        group: 'ggg_finance_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1TuwoibWGI-kflce9VbPrtc5_s-4r3ByuoKXRgxCVHXA',
      },
      {
        group: 'ggg_online_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1YYQ-ZNwSMvLk13ZwTSRe_ovSs-vC3bL9J6FfUQl2bSM',
      },
      {
        group: 'ggg_procurement_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1lRokDIG2OqljJQhY5Ht2aKA6b8n7vk2Mk0HUoTxmgD4',
      },
      {
        group:
          'ggg_productivity_operations_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1yOBFrxoJI-Yw_gteYrlmnVNf8u23cKxSNXaXds4zoG8',
      },
      {
        group: 'ggg_supply_chain_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1ZyMlPR-bmwaervE4RkqU2AHcOREXUG0kw-LRbQ5g6kQ',
      },
      {
        group: 'ggg_property_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1H7CMLcd7wJbKfcF55JzOG7KqZOOAvCupBp37F5vMMZ0',
      },
      {
        group: 'man-my-working-pattern-services-ug@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/19QZcG9VsjPAc7ry8evsFb7F_cbD4XzdZMJ7IM4w2oso',
      },
      {
        group: 'ggg_shared_services_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1lAIV7eqxSKkZathUQgn-rAgAN4CAbsYcHCdF9r9qbm8',
      },
      {
        group: 'ggg_wholesale_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1Rrt2MVbLya-z-8mq9CbtteD8_Z9l8dzlYtEIKPl4RJo',
      },
      {
        group:
          'man-my-working-pattern---coalville-nutmeg-ug@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/182MeWR4F7Y8aHePWT0XK8YbjiDPvMojcqil8NS5U_M0',
      },
    ],
    yourSaySurveyLink: 'https://dev.workl.co/saml-morrisons',
    yourSayDashboard: 'https://dev.business.workl.co/saml-morrisons',
    empcenterMyScheduleLink:
      'https://morrisons-test.wta-eu3.wfs.cloud/workforce/Desktop.do',
      covid19Link: 'http://localhost:3000/sites/covid-19',
      diamondsFormLink: 'https://forms.gle/VSGg1RT7myxWZDvw8',
      morrisonsControlTower: {
        morrisonsControlTower : 'https://morrisonscontroltower.uat.testapps.mymorri.com',
        userGuide : 'https://docs.google.com/presentation/d/1_dgBjStO_oFeWt2v-qzYZmM1BS9ku8KBFb6fquhaSFU/edit?usp=sharing'
      },
  },
  dev: {
    epayslips_url: 'https://acn-epayslips.appspot.com',
    activity_planner_url: 'https://acn-activity-planner.appspot.com/quarter',
    user_provisioning_url: 'https://morrisons-user-provisioning.appspot.com',
    // client_id:
    //   '858113026121-ost1pr84avr3tlnroahnaiidpr369mq9.apps.googleusercontent.com',
    client_id:
    '877614078106-ap72ofvi4b7d8rc6f3ad6rd6j01d22k2.apps.googleusercontent.com',
    email_domain: 'test.morrisonsplc.co.uk',
    leavers_group: 'morrisons.leavers@test.morrisonsplc.co.uk',
    payslips_group: 'morrisons.payslips@test.morrisonsplc.co.uk',
    thinkShrink_group: 'man-mymorri-shrink-tile-ug@test.morrisonsplc.co.uk',
    salaried_group: 'ggg_salaried_colleagues@test.morrisonsplc.co.uk',
    hourly_group: 'ggg_hourly_colleagues@test.morrisonsplc.co.uk',
    store_group: 'ggg_store_colleagues@test.morrisonsplc.co.uk',
    central_group: 'ggg_central_colleagues@test.morrisonsplc.co.uk',
    central_hilmore_group:
      'ggg_hilmore_central_colleagues@test.morrisonsplc.co.uk',
    central_field_group: 'ggg_field_central_colleagues@test.morrisonsplc.co.uk',
    activityPlanner_super_admin_group:
      'activity.planner.admin.super@test.morrisonsplc.co.uk',
    skipton_colleague_group: 'ggg_056_colleagues@test.morrisonsplc.co.uk',
    performSalaried:
      'https://sites.google.com/test.morrisonsplc.co.uk/myperformance/salary-colleagues',
    performGeneric:
      'https://sites.google.com/test.morrisonsplc.co.uk/myperformance/home',
    performHourly:
      'https://sites.google.com/test.morrisonsplc.co.uk/myperformance/hourly-colleagues',
    reflexisLink: 'https://morrisons.reflexisinc.co.uk/RWS4',
    holidaysApp:
      'https://sites.google.com/a/morrisonsplc.co.uk/morrison-news/my-rotas-and-my-holidays',

    holidaysAppLink:
      'https://script.google.com/a/test.morrisonsplc.co.uk/macros/s/AKfycbweMN4avh3CywTCOVJxVwO1yojB5cgw1VLbReRDzYnDQlI6JIk/exec',
    handbookDefaultLink:
      'https://sites.google.com/morrisonsplc.co.uk/myhandbook-retail/home',
    communitiesLink: 'http://chat.google.com/',
    leavers_questionnaire:
      'https://docs.google.com/forms/d/e/1FAIpQLSfhGZCj0vBioUtH_Q2TxGun4lnC_1gBoIxu0SZUqwQ1DuBfaQ/viewform?usp=sf_link',
    dataProtectionLink:
      'https://sites.google.com/morrisonsplc.co.uk/data-protection-2019/home',

    colleagueDiscountManagementLink:
      'https://pepl-colleaguediscountsys-dev.web.app/home',
    colleagueDiscountRegistrationLink:
      'https://pepl-colleaguediscount-dev-fb.firebaseapp.com',

    google_analytics_id: 'G-NJ1T4L1CJW',
    contentfullApi: {
      space: 'fwof4kybqy1w',
      environment: 'test',
      accessToken: ' ',
      host: window.location.hostname,
      basePath: '/api/contentful',
    },
    retailCommsContentfulApi: {
      space: 'lzjg12b015yu',
      environment: 'test',
      accessToken: ' ',
      host: window.location.hostname,
      basePath: '/api/contentful',
    },
    archive: {
      lastFinancialYear: {
        year: '2020-2021',
        startWeek: '2020-02-03T00:00:00.000Z',
        endWeek: '2021-01-31T06:08:04.000Z',
      },
      years: [
        {
          year: '2021/2022',
          startWeek: '2021-02-01T00:00:00.000Z',
          endWeek: '2022-01-30T06:08:04.000Z',
        },
        {
          year: '2020/2021',
          startWeek: '2020-02-03T00:00:00.000Z',
          endWeek: '2021-01-31T06:08:04.000Z',
        },
        {
          year: '2019/2020',
          startWeek: '2019-02-04T00:00:00.000Z',
          endWeek: '2020-02-02T06:08:04.000Z',
        },
      ],
    },
    twoStepLink: 'https://g.co/2sv',
    handbookLinkRetailNPS:
      'https://sites.google.com/morrisonsplc.co.uk/my-handbook-retail-2-nps',
    handbook: [
      {
        group: 'ggg_store_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/my-handbook-retail-2-nps/home',
      },
      {
        group: 'ggg_manufacturing_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/myhandbook-manufacturing/home',
      },
      {
        group: 'ggg_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/myhandbook-hilmorehouse/home',
      },
      {
        group: 'ggg_swanvalley_distribution_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/my-handbook-swanvalley/home',
      },
      {
        group: 'ggg_stockton_distribution_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/myhandbook-stockton/home',
      },
      {
        group: 'ggg_sttngbrne_distribution_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/my-handbook-sittingbourne/home',
      },
      {
        group: 'ggg_latimer_distribution_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/my-handbook-latimerpark-corby/home',
      },
      {
        group: 'ggg_corby_distribution_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/my-handbook-latimerpark-corby/home',
      },
      {
        group: 'ggg_j41_distribution_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/myhandbook-wakefieldj41/home',
      },
      {
        group: 'ggg_gadbrook_distribution_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/my-handbook-gadbrook/home',
      },
      {
        group: 'ggg_gadbrookl_distribution_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/my-handbook-gadbrook/home',
      },
      {
        group: 'morrisons.leavers@test.morrisonsplc.co.uk',
        link:
          'https://pepl-leavershb-spt-fb.firebaseapp.com',
      },
      {
        group: 'ggg_bridgwater_distribution_colleagues@test.morrisonsplc.co.uk',
        link: 'https://sites.google.com/morrisonsplc.co.uk/willowgreen1/home',
      },
    ],
    workingPattern: [
      {
        group: 'ggg_people_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1t6caE7MJJ7W1prCOS3G3U_mQwrxy_EQOwAfm28TsB8o',
      },
      {
        group: 'ggg_technology_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1qqMHXhWI6ovRXYipHWzqLQfTN95e_rg5Ixe8aN_2rUA/edit?usp=sharing',
      },
      {
        group:
          'man-myworkingpattern-centrallogistics-ug@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1WAAqJQ_y0NOxujFfkPHjkivzsUv-OBhKoO-yEyyOiuM',
      },
      {
        group:
          'man-my-working-pattern---central-manufacturing-ug@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1Ob5qf2uwQTiYRzfkfaBZ0JurowCvnY0cDJbnciiGFjo',
      },
      {
        group: 'ggg_retail_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1YjtfekcoHH2EvmnfhVh7zuxy1HBxAT3L0FnSNu-8Kt8',
      },
      {
        group: 'ggg_loss_prevention_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1tk7fYzNntSz-BfwKebuh3oSaT0qxZ2LIYeifpa1zCtE',
      },
      {
        group: 'ggg_commercial_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1Ez255TEvyBgO5vhQ3kGYX1vRpoN37zXbggXs0vJSF3Y',
      },
      {
        group: 'ggg_marketing_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1FgxSG1lhJZZyOJz8BTVuAuKbySxyI9Z7MXidOM3kQ_8',
      },
      {
        group:
          'man-my-working-pattern-contact-centre-ug@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1TNhD6iSEuLP0x0Y6FCUoXJaX9H-Uj6g5cinUWUGBpZU',
      },
      {
        group:
          'ggg_corporate_services_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1W4O8zFxL3VIrMMzUIj0SixROrdPfHuxaETWo0RHP4lU',
      },
      {
        group: 'man-my-working-pattern-directorate-ug@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1HKltsFQjUWuOj8VneVDGRDJZ8l2iVDfcEoPLJpY7yuc',
      },
      {
        group: 'ggg_finance_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1TuwoibWGI-kflce9VbPrtc5_s-4r3ByuoKXRgxCVHXA',
      },
      {
        group: 'ggg_online_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1YYQ-ZNwSMvLk13ZwTSRe_ovSs-vC3bL9J6FfUQl2bSM',
      },
      {
        group: 'ggg_procurement_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1lRokDIG2OqljJQhY5Ht2aKA6b8n7vk2Mk0HUoTxmgD4',
      },
      {
        group:
          'ggg_productivity_operations_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1yOBFrxoJI-Yw_gteYrlmnVNf8u23cKxSNXaXds4zoG8',
      },
      {
        group: 'ggg_supply_chain_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1ZyMlPR-bmwaervE4RkqU2AHcOREXUG0kw-LRbQ5g6kQ',
      },
      {
        group: 'ggg_property_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1H7CMLcd7wJbKfcF55JzOG7KqZOOAvCupBp37F5vMMZ0',
      },
      {
        group: 'man-my-working-pattern-services-ug@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/19QZcG9VsjPAc7ry8evsFb7F_cbD4XzdZMJ7IM4w2oso',
      },
      {
        group: 'ggg_shared_services_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1lAIV7eqxSKkZathUQgn-rAgAN4CAbsYcHCdF9r9qbm8',
      },
      {
        group: 'ggg_wholesale_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1Rrt2MVbLya-z-8mq9CbtteD8_Z9l8dzlYtEIKPl4RJo',
      },
      {
        group:
          'man-my-working-pattern---coalville-nutmeg-ug@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/182MeWR4F7Y8aHePWT0XK8YbjiDPvMojcqil8NS5U_M0',
      },
    ],
    yourSaySurveyLink: 'https://dev.workl.co/saml-morrisons',
    yourSayDashboard: 'https://dev.business.workl.co/saml-morrisons',
    empcenterMyScheduleLink:
      'https://morrisons-test.wta-eu3.wfs.cloud/workforce/Desktop.do',
    covid19Link: 'https://acn-my-morri.appspot.com/sites/covid-19',
    diamondsFormLink: 'https://forms.gle/VSGg1RT7myxWZDvw8',
    morrisonsControlTower: {
      morrisonsControlTower : 'https://morrisonscontroltower.uat.testapps.mymorri.com',
      userGuide : 'https://docs.google.com/presentation/d/1_dgBjStO_oFeWt2v-qzYZmM1BS9ku8KBFb6fquhaSFU/edit?usp=sharing'
    },
  },
  qa: {
    epayslips_url: 'https://acn-epayslips-qa.appspot.com',
    activity_planner_url: 'https://acn-activity-planner-qa.appspot.com/quarter',
    user_provisioning_url: 'https://morrisons-user-provisioning.appspot.com',
    client_id:
      '877614078106-ap72ofvi4b7d8rc6f3ad6rd6j01d22k2.apps.googleusercontent.com',
    email_domain: 'test.morrisonsplc.co.uk',
    leavers_group: 'morrisons.leavers@test.morrisonsplc.co.uk',
    payslips_group: 'morrisons.payslips@test.morrisonsplc.co.uk',
    thinkShrink_group: 'man-mymorri-shrink-tile-ug@test.morrisonsplc.co.uk',
    salaried_group: 'ggg_salaried_colleagues@test.morrisonsplc.co.uk',
    hourly_group: 'ggg_hourly_colleagues@test.morrisonsplc.co.uk',
    store_group: 'ggg_store_colleagues@test.morrisonsplc.co.uk',
    central_group: 'ggg_central_colleagues@test.morrisonsplc.co.uk',
    central_hilmore_group:
      'ggg_hilmore_central_colleagues@test.morrisonsplc.co.uk',
    central_field_group: 'ggg_field_central_colleagues@test.morrisonsplc.co.uk',
    activityPlanner_super_admin_group:
      'activity.planner.admin.super@test.morrisonsplc.co.uk',
    skipton_colleague_group: 'ggg_056_colleagues@test.morrisonsplc.co.uk',
    performSalaried:
      'https://sites.google.com/test.morrisonsplc.co.uk/myperformance/salary-colleagues',
    performHourly:
      'https://sites.google.com/test.morrisonsplc.co.uk/myperformance/hourly-colleagues',
    performGeneric:
      'https://sites.google.com/test.morrisonsplc.co.uk/myperformance/home',
    reflexisLink: 'https://morrisons.reflexisinc.co.uk/RWS4',
    holidaysApp:
      'https://sites.google.com/a/morrisonsplc.co.uk/morrison-news/my-rotas-and-my-holidays',
    handbookDefaultLink:
      'https://sites.google.com/morrisonsplc.co.uk/myhandbook-retail/home',
    holidaysAppLink:
      'https://script.google.com/a/test.morrisonsplc.co.uk/macros/s/AKfycbweMN4avh3CywTCOVJxVwO1yojB5cgw1VLbReRDzYnDQlI6JIk/exec',
    communitiesLink: 'http://chat.google.com/',
    leavers_questionnaire:
      'https://docs.google.com/forms/d/e/1FAIpQLSfhGZCj0vBioUtH_Q2TxGun4lnC_1gBoIxu0SZUqwQ1DuBfaQ/viewform?usp=sf_link',
    dataProtectionLink:
      'https://sites.google.com/morrisonsplc.co.uk/data-protection-2019/home',

    colleagueDiscountManagementLink:
      'https://pepl-colleaguediscountsys-uat.web.app/home',
    colleagueDiscountRegistrationLink:
      'https://pepl-colleaguediscount-dev-fb.firebaseapp.com',

    google_analytics_id: 'G-9GZ6P4XKZF',
    contentfullApi: {
      space: 'fwof4kybqy1w',
      environment: 'test',
      accessToken: ' ',
      host: window.location.hostname,
      basePath: '/api/contentful',
    },
    retailCommsContentfulApi: {
      space: 'lzjg12b015yu',
      environment: 'test',
      accessToken: ' ',
      host: window.location.hostname,
      basePath: '/api/contentful',
    },
    archive: {
      lastFinancialYear: {
        year: '2020-2021',
        startWeek: '2020-02-03T00:00:00.000Z',
        endWeek: '2021-01-31T06:08:04.000Z',
      },
      years: [
        {
          year: '2021/2022',
          startWeek: '2021-02-01T00:00:00.000Z',
          endWeek: '2022-01-30T06:08:04.000Z',
        },
        {
          year: '2020/2021',
          startWeek: '2020-02-03T00:00:00.000Z',
          endWeek: '2021-01-31T06:08:04.000Z',
        },
        {
          year: '2019/2020',
          startWeek: '2019-02-04T00:00:00.000Z',
          endWeek: '2020-02-02T06:08:04.000Z',
        },
      ],
    },
    twoStepLink: 'https://g.co/2sv',
    handbookLinkRetailNPS:
      'https://sites.google.com/morrisonsplc.co.uk/my-handbook-retail-2-nps',
    handbook: [
      {
        group: 'ggg_store_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/my-handbook-retail-2-nps/home',
      },
      {
        group: 'ggg_manufacturing_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/myhandbook-manufacturing/home',
      },
      {
        group: 'ggg_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/myhandbook-hilmorehouse/home',
      },
      {
        group: 'ggg_swanvalley_distribution_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/my-handbook-swanvalley/home',
      },
      {
        group: 'ggg_stockton_distribution_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/myhandbook-stockton/home',
      },
      {
        group: 'ggg_sttngbrne_distribution_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/my-handbook-sittingbourne/home',
      },
      {
        group: 'ggg_latimer_distribution_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/my-handbook-latimerpark-corby/home',
      },
      {
        group: 'ggg_corby_distribution_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/my-handbook-latimerpark-corby/home',
      },
      {
        group: 'ggg_j41_distribution_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/myhandbook-wakefieldj41/home',
      },
      {
        group: 'ggg_gadbrook_distribution_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/my-handbook-gadbrook/home',
      },
      {
        group: 'ggg_gadbrookl_distribution_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/my-handbook-gadbrook/home',
      },
      {
        group: 'morrisons.leavers@test.morrisonsplc.co.uk',
        link:
          'https://pepl-leavershb-spt-fb.firebaseapp.com',
      },
      {
        group: 'ggg_bridgwater_distribution_colleagues@test.morrisonsplc.co.uk',
        link: 'https://sites.google.com/morrisonsplc.co.uk/willowgreen1/home',
      },
    ],
    workingPattern: [
      {
        group: 'ggg_people_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1t6caE7MJJ7W1prCOS3G3U_mQwrxy_EQOwAfm28TsB8o',
      },
      {
        group: 'ggg_technology_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1qqMHXhWI6ovRXYipHWzqLQfTN95e_rg5Ixe8aN_2rUA/edit?usp=sharing',
      },
      {
        group:
          'man-myworkingpattern-centrallogistics-ug@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1WAAqJQ_y0NOxujFfkPHjkivzsUv-OBhKoO-yEyyOiuM',
      },
      {
        group:
          'man-my-working-pattern---central-manufacturing-ug@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1Ob5qf2uwQTiYRzfkfaBZ0JurowCvnY0cDJbnciiGFjo',
      },
      {
        group: 'ggg_retail_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1YjtfekcoHH2EvmnfhVh7zuxy1HBxAT3L0FnSNu-8Kt8',
      },
      {
        group: 'ggg_loss_prevention_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1tk7fYzNntSz-BfwKebuh3oSaT0qxZ2LIYeifpa1zCtE',
      },
      {
        group: 'ggg_commercial_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1Ez255TEvyBgO5vhQ3kGYX1vRpoN37zXbggXs0vJSF3Y',
      },
      {
        group: 'ggg_marketing_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1FgxSG1lhJZZyOJz8BTVuAuKbySxyI9Z7MXidOM3kQ_8',
      },
      {
        group:
          'man-my-working-pattern-contact-centre-ug@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1TNhD6iSEuLP0x0Y6FCUoXJaX9H-Uj6g5cinUWUGBpZU',
      },
      {
        group:
          'ggg_corporate_services_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1W4O8zFxL3VIrMMzUIj0SixROrdPfHuxaETWo0RHP4lU',
      },
      {
        group: 'man-my-working-pattern-directorate-ug@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1HKltsFQjUWuOj8VneVDGRDJZ8l2iVDfcEoPLJpY7yuc',
      },
      {
        group: 'ggg_finance_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1TuwoibWGI-kflce9VbPrtc5_s-4r3ByuoKXRgxCVHXA',
      },
      {
        group: 'ggg_online_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1YYQ-ZNwSMvLk13ZwTSRe_ovSs-vC3bL9J6FfUQl2bSM',
      },
      {
        group: 'ggg_procurement_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1lRokDIG2OqljJQhY5Ht2aKA6b8n7vk2Mk0HUoTxmgD4',
      },
      {
        group:
          'ggg_productivity_operations_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1yOBFrxoJI-Yw_gteYrlmnVNf8u23cKxSNXaXds4zoG8',
      },
      {
        group: 'ggg_supply_chain_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1ZyMlPR-bmwaervE4RkqU2AHcOREXUG0kw-LRbQ5g6kQ',
      },
      {
        group: 'ggg_property_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1H7CMLcd7wJbKfcF55JzOG7KqZOOAvCupBp37F5vMMZ0',
      },
      {
        group: 'man-my-working-pattern-services-ug@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/19QZcG9VsjPAc7ry8evsFb7F_cbD4XzdZMJ7IM4w2oso',
      },
      {
        group: 'ggg_shared_services_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1lAIV7eqxSKkZathUQgn-rAgAN4CAbsYcHCdF9r9qbm8',
      },
      {
        group: 'ggg_wholesale_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1Rrt2MVbLya-z-8mq9CbtteD8_Z9l8dzlYtEIKPl4RJo',
      },
      {
        group:
          'man-my-working-pattern---coalville-nutmeg-ug@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/182MeWR4F7Y8aHePWT0XK8YbjiDPvMojcqil8NS5U_M0',
      },
    ],
    yourSaySurveyLink: 'https://dev.workl.co/saml-morrisons',
    yourSayDashboard: 'https://dev.business.workl.co/saml-morrisons',
    empcenterMyScheduleLink:
      'https://morrisons-test.wta-eu3.wfs.cloud/workforce/Desktop.do',
      covid19Link: 'https://acn-my-morri-qa.appspot.com/sites/covid-19',
      diamondsFormLink: 'https://forms.gle/VSGg1RT7myxWZDvw8',
      morrisonsControlTower: {
        morrisonsControlTower : 'https://morrisonscontroltower.uat.testapps.mymorri.com',
        userGuide : 'https://docs.google.com/presentation/d/1_dgBjStO_oFeWt2v-qzYZmM1BS9ku8KBFb6fquhaSFU/edit?usp=sharing'
      },
    },
  staging: {
    epayslips_url: 'https://acn-epayslips-staging.appspot.com',
    activity_planner_url:
      'https://acn-activity-planner-staging.appspot.com/quarter',
    user_provisioning_url: 'https://morrisons-user-provisioning.appspot.com',
    client_id:
      '877614078106-ap72ofvi4b7d8rc6f3ad6rd6j01d22k2.apps.googleusercontent.com',
    email_domain: 'test.morrisonsplc.co.uk',
    leavers_group: 'morrisons.leavers@test.morrisonsplc.co.uk',
    payslips_group: 'morrisons.payslips@test.morrisonsplc.co.uk',
    thinkShrink_group: 'man-mymorri-shrink-tile-ug@test.morrisonsplc.co.uk',
    salaried_group: 'ggg_salaried_colleagues@test.morrisonsplc.co.uk',
    hourly_group: 'ggg_hourly_colleagues@test.morrisonsplc.co.uk',
    store_group: 'ggg_store_colleagues@test.morrisonsplc.co.uk',
    central_group: 'ggg_central_colleagues@test.morrisonsplc.co.uk',
    central_hilmore_group:
      'ggg_hilmore_central_colleagues@test.morrisonsplc.co.uk',
    central_field_group: 'ggg_field_central_colleagues@test.morrisonsplc.co.uk',
    activityPlanner_super_admin_group:
      'activity.planner.admin.super@test.morrisonsplc.co.uk',
    skipton_colleague_group: 'ggg_056_colleagues@test.morrisonsplc.co.uk',
    performSalaried:
      'https://sites.google.com/test.morrisonsplc.co.uk/myperformance/salary-colleagues',
    performHourly:
      'https://sites.google.com/test.morrisonsplc.co.uk/myperformance/hourly-colleagues',
    performGeneric:
      'https://sites.google.com/test.morrisonsplc.co.uk/myperformance/home',
    reflexisLink: 'https://morrisons.reflexisinc.co.uk/RWS4',
    holidaysApp:
      'https://sites.google.com/a/morrisonsplc.co.uk/morrison-news/my-rotas-and-my-holidays',
    handbookDefaultLink:
      'https://sites.google.com/morrisonsplc.co.uk/myhandbook-retail/home',
    holidaysAppLink:
      'https://script.google.com/a/test.morrisonsplc.co.uk/macros/s/AKfycbweMN4avh3CywTCOVJxVwO1yojB5cgw1VLbReRDzYnDQlI6JIk/exec',
    communitiesLink: 'http://chat.google.com/',
    leavers_questionnaire:
      'https://docs.google.com/forms/d/e/1FAIpQLSfhGZCj0vBioUtH_Q2TxGun4lnC_1gBoIxu0SZUqwQ1DuBfaQ/viewform?usp=sf_link',
    dataProtectionLink:
      'https://sites.google.com/morrisonsplc.co.uk/data-protection-2019/home',

    colleagueDiscountManagementLink:
      'https://pepl-colleaguediscountsys-sit.web.app/home',
    colleagueDiscountRegistrationLink:
      'https://pepl-colleaguediscount-dev-fb.firebaseapp.com',

    google_analytics_id: 'G-MVZ7TZMK9R',
    twoStepLink: 'https://g.co/2sv',
    contentfullApi: {
      space: 'fwof4kybqy1w',
      environment: 'master',
      accessToken: 'MYMORRI_CONTENTFUL_ACCESS_TOKEN_STAGING',
      host: 'preview.contentful.com',
    },
    retailCommsContentfulApi: {
      space: 'lzjg12b015yu',
      environment: 'master',
      accessToken: 'RETAILCOMMS_CONTENTFUL_ACCESS_TOKEN_STAGING',
      host: 'preview.contentful.com',
    },
    archive: {
      lastFinancialYear: {
        year: '2020-2021',
        startWeek: '2020-02-03T00:00:00.000Z',
        endWeek: '2021-01-31T06:08:04.000Z',
      },
      years: [
        {
          year: '2021/2022',
          startWeek: '2021-02-01T00:00:00.000Z',
          endWeek: '2022-01-30T06:08:04.000Z',
        },
        {
          year: '2020/2021',
          startWeek: '2020-02-03T00:00:00.000Z',
          endWeek: '2021-01-31T06:08:04.000Z',
        },
        {
          year: '2019/2020',
          startWeek: '2019-02-04T00:00:00.000Z',
          endWeek: '2020-02-02T06:08:04.000Z',
        },
      ],
    },
    handbookLinkRetailNPS:
      'https://sites.google.com/morrisonsplc.co.uk/my-handbook-retail-2-nps',
    handbook: [
      {
        group: 'ggg_store_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/my-handbook-retail-2-nps/home',
      },
      {
        group: 'ggg_manufacturing_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/myhandbook-manufacturing/home',
      },
      {
        group: 'ggg_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/myhandbook-hilmorehouse/home',
      },
      {
        group: 'ggg_swanvalley_distribution_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/my-handbook-swanvalley/home',
      },
      {
        group: 'ggg_stockton_distribution_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/myhandbook-stockton/home',
      },
      {
        group: 'ggg_sttngbrne_distribution_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/my-handbook-sittingbourne/home',
      },
      {
        group: 'ggg_latimer_distribution_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/my-handbook-latimerpark-corby/home',
      },
      {
        group: 'ggg_corby_distribution_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/my-handbook-latimerpark-corby/home',
      },
      {
        group: 'ggg_j41_distribution_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/myhandbook-wakefieldj41/home',
      },
      {
        group: 'ggg_gadbrook_distribution_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/my-handbook-gadbrook/home',
      },
      {
        group: 'ggg_gadbrookl_distribution_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/my-handbook-gadbrook/home',
      },
      {
        group: 'morrisons.leavers@test.morrisonsplc.co.uk',
        link:
          'https://pepl-leavershb-spt-fb.firebaseapp.com',
      },
      {
        group: 'ggg_bridgwater_distribution_colleagues@test.morrisonsplc.co.uk',
        link: 'https://sites.google.com/morrisonsplc.co.uk/willowgreen1/home',
      },
    ],
    workingPattern: [
      {
        group: 'ggg_people_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1t6caE7MJJ7W1prCOS3G3U_mQwrxy_EQOwAfm28TsB8o',
      },
      {
        group: 'ggg_technology_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1qqMHXhWI6ovRXYipHWzqLQfTN95e_rg5Ixe8aN_2rUA/edit?usp=sharing',
      },
      {
        group:
          'man-myworkingpattern-centrallogistics-ug@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1WAAqJQ_y0NOxujFfkPHjkivzsUv-OBhKoO-yEyyOiuM',
      },
      {
        group:
          'man-my-working-pattern---central-manufacturing-ug@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1Ob5qf2uwQTiYRzfkfaBZ0JurowCvnY0cDJbnciiGFjo',
      },
      {
        group: 'ggg_retail_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1YjtfekcoHH2EvmnfhVh7zuxy1HBxAT3L0FnSNu-8Kt8',
      },
      {
        group: 'ggg_loss_prevention_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1tk7fYzNntSz-BfwKebuh3oSaT0qxZ2LIYeifpa1zCtE',
      },
      {
        group: 'ggg_commercial_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1Ez255TEvyBgO5vhQ3kGYX1vRpoN37zXbggXs0vJSF3Y',
      },
      {
        group: 'ggg_marketing_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1FgxSG1lhJZZyOJz8BTVuAuKbySxyI9Z7MXidOM3kQ_8',
      },
      {
        group:
          'man-my-working-pattern-contact-centre-ug@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1TNhD6iSEuLP0x0Y6FCUoXJaX9H-Uj6g5cinUWUGBpZU',
      },
      {
        group:
          'ggg_corporate_services_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1W4O8zFxL3VIrMMzUIj0SixROrdPfHuxaETWo0RHP4lU',
      },
      {
        group: 'man-my-working-pattern-directorate-ug@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1HKltsFQjUWuOj8VneVDGRDJZ8l2iVDfcEoPLJpY7yuc',
      },
      {
        group: 'ggg_finance_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1TuwoibWGI-kflce9VbPrtc5_s-4r3ByuoKXRgxCVHXA',
      },
      {
        group: 'ggg_online_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1YYQ-ZNwSMvLk13ZwTSRe_ovSs-vC3bL9J6FfUQl2bSM',
      },
      {
        group: 'ggg_procurement_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1lRokDIG2OqljJQhY5Ht2aKA6b8n7vk2Mk0HUoTxmgD4',
      },
      {
        group:
          'ggg_productivity_operations_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1yOBFrxoJI-Yw_gteYrlmnVNf8u23cKxSNXaXds4zoG8',
      },
      {
        group: 'ggg_supply_chain_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1ZyMlPR-bmwaervE4RkqU2AHcOREXUG0kw-LRbQ5g6kQ',
      },
      {
        group: 'ggg_property_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1H7CMLcd7wJbKfcF55JzOG7KqZOOAvCupBp37F5vMMZ0',
      },
      {
        group: 'man-my-working-pattern-services-ug@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/19QZcG9VsjPAc7ry8evsFb7F_cbD4XzdZMJ7IM4w2oso',
      },
      {
        group: 'ggg_shared_services_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1lAIV7eqxSKkZathUQgn-rAgAN4CAbsYcHCdF9r9qbm8',
      },
      {
        group: 'ggg_wholesale_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1Rrt2MVbLya-z-8mq9CbtteD8_Z9l8dzlYtEIKPl4RJo',
      },
      {
        group:
          'man-my-working-pattern---coalville-nutmeg-ug@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/182MeWR4F7Y8aHePWT0XK8YbjiDPvMojcqil8NS5U_M0',
      },
    ],
    yourSaySurveyLink: 'https://dev.workl.co/saml-morrisons',
    yourSayDashboard: 'https://dev.business.workl.co/saml-morrisons',
    empcenterMyScheduleLink:
      'https://morrisons-test.wta-eu3.wfs.cloud/workforce/Desktop.do',
    covid19Link: 'https://acn-my-morri-staging.appspot.com/sites/covid-19',
    diamondsFormLink: 'https://forms.gle/VSGg1RT7myxWZDvw8',
    morrisonsControlTower: {
      morrisonsControlTower : 'https://morrisonscontroltower.uat.testapps.mymorri.com',
      userGuide : 'https://docs.google.com/presentation/d/1_dgBjStO_oFeWt2v-qzYZmM1BS9ku8KBFb6fquhaSFU/edit?usp=sharing'
    },
  },
  preprod: {
    epayslips_url: 'https://morrisons-epayslips-pre.appspot.com',
    activity_planner_url:
      'https://morrisons-activity-planner.appspot.com/quarter',
    user_provisioning_url: 'https://morrisons-user-provisioning.appspot.com',
    client_id:
      '877614078106-ap72ofvi4b7d8rc6f3ad6rd6j01d22k2.apps.googleusercontent.com',
    email_domain: 'test.morrisonsplc.co.uk',
    leavers_group: 'morrisons.leavers@test.morrisonsplc.co.uk',
    payslips_group: 'morrisons.payslips@test.morrisonsplc.co.uk',
    thinkShrink_group: 'man-mymorri-shrink-tile-ug@test.morrisonsplc.co.uk',
    salaried_group: 'ggg_salaried_colleagues@test.morrisonsplc.co.uk',
    hourly_group: 'ggg_hourly_colleagues@test.morrisonsplc.co.uk',
    store_group: 'ggg_store_colleagues@test.morrisonsplc.co.uk',
    central_group: 'ggg_central_colleagues@test.morrisonsplc.co.uk',
    central_hilmore_group:
      'ggg_hilmore_central_colleagues@test.morrisonsplc.co.uk',
    central_field_group: 'ggg_field_central_colleagues@test.morrisonsplc.co.uk',
    activityPlanner_super_admin_group:
      'activity.planner.admin.super@test.morrisonsplc.co.uk',
    skipton_colleague_group: 'ggg_056_colleagues@test.morrisonsplc.co.uk',
    performSalaried:
      'https://sites.google.com/test.morrisonsplc.co.uk/myperformance/salary-colleagues',
    performGeneric:
      'https://sites.google.com/test.morrisonsplc.co.uk/myperformance/home',
    performHourly:
      'https://sites.google.com/test.morrisonsplc.co.uk/myperformance/hourly-colleagues',
    reflexisLink: 'https://morrisons.reflexisinc.co.uk/RWS4',
    holidaysApp:
      'https://sites.google.com/a/morrisonsplc.co.uk/morrison-news/my-rotas-and-my-holidays',

    holidaysAppLink:
      'https://script.google.com/a/test.morrisonsplc.co.uk/macros/s/AKfycbweMN4avh3CywTCOVJxVwO1yojB5cgw1VLbReRDzYnDQlI6JIk/exec',
    handbookDefaultLink:
      'https://sites.google.com/morrisonsplc.co.uk/myhandbook-retail/home',
    communitiesLink: 'http://chat.google.com/',
    leavers_questionnaire:
      'https://docs.google.com/forms/d/e/1FAIpQLSfhGZCj0vBioUtH_Q2TxGun4lnC_1gBoIxu0SZUqwQ1DuBfaQ/viewform?usp=sf_link',
    dataProtectionLink:
      'https://sites.google.com/morrisonsplc.co.uk/data-protection-2019/home',

    colleagueDiscountManagementLink:
      'https://pepl-colleaguediscountsys-sit.web.app/home',
    colleagueDiscountRegistrationLink:
      'https://pepl-colleaguediscount-dev-fb.firebaseapp.com',

    google_analytics_id: 'G-570KLVE607',
    twoStepLink: 'https://g.co/2sv',
    contentfullApi: {
      space: 'fwof4kybqy1w',
      environment: 'master',
      accessToken: ' ',
      host: window.location.hostname,
      basePath: '/api/contentful',
    },
    retailCommsContentfulApi: {
      space: 'lzjg12b015yu',
      environment: 'master',
      accessToken: ' ',
      host: window.location.hostname,
      basePath: '/api/contentful',
    },
    archive: {
      lastFinancialYear: {
        year: '2020-2021',
        startWeek: '2020-02-03T00:00:00.000Z',
        endWeek: '2021-01-31T06:08:04.000Z',
      },
      years: [
        {
          year: '2021/2022',
          startWeek: '2021-02-01T00:00:00.000Z',
          endWeek: '2022-01-30T06:08:04.000Z',
        },
        {
          year: '2020/2021',
          startWeek: '2020-02-03T00:00:00.000Z',
          endWeek: '2021-01-31T06:08:04.000Z',
        },
        {
          year: '2019/2020',
          startWeek: '2019-02-04T00:00:00.000Z',
          endWeek: '2020-02-02T06:08:04.000Z',
        },
      ],
    },
    handbookLinkRetailNPS:
      'https://sites.google.com/morrisonsplc.co.uk/my-handbook-retail-2-nps',
    handbook: [
      {
        group: 'ggg_store_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/my-handbook-retail-2-nps/home',
      },
      {
        group: 'ggg_manufacturing_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/myhandbook-manufacturing/home',
      },
      {
        group: 'ggg_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/myhandbook-hilmorehouse/home',
      },
      {
        group: 'ggg_swanvalley_distribution_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/my-handbook-swanvalley/home',
      },
      {
        group: 'ggg_stockton_distribution_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/myhandbook-stockton/home',
      },
      {
        group: 'ggg_sttngbrne_distribution_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/my-handbook-sittingbourne/home',
      },
      {
        group: 'ggg_latimer_distribution_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/my-handbook-latimerpark-corby/home',
      },
      {
        group: 'ggg_corby_distribution_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/my-handbook-latimerpark-corby/home',
      },
      {
        group: 'ggg_j41_distribution_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/myhandbook-wakefieldj41/home',
      },
      {
        group: 'ggg_gadbrook_distribution_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/my-handbook-gadbrook/home',
      },
      {
        group: 'ggg_gadbrookl_distribution_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/my-handbook-gadbrook/home',
      },
      {
        group: 'morrisons.leavers@test.morrisonsplc.co.uk',
        link:
          'https://pepl-leavershb-spt-fb.firebaseapp.com',
      },
      {
        group: 'ggg_bridgwater_distribution_colleagues@test.morrisonsplc.co.uk',
        link: 'https://sites.google.com/morrisonsplc.co.uk/willowgreen1/home',
      },
    ],
    workingPattern: [
      {
        group: 'ggg_people_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1t6caE7MJJ7W1prCOS3G3U_mQwrxy_EQOwAfm28TsB8o',
      },
      {
        group: 'ggg_technology_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1qqMHXhWI6ovRXYipHWzqLQfTN95e_rg5Ixe8aN_2rUA/edit?usp=sharing',
      },
      {
        group:
          'man-myworkingpattern-centrallogistics-ug@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1WAAqJQ_y0NOxujFfkPHjkivzsUv-OBhKoO-yEyyOiuM',
      },
      {
        group:
          'man-my-working-pattern---central-manufacturing-ug@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1Ob5qf2uwQTiYRzfkfaBZ0JurowCvnY0cDJbnciiGFjo',
      },
      {
        group: 'ggg_retail_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1YjtfekcoHH2EvmnfhVh7zuxy1HBxAT3L0FnSNu-8Kt8',
      },
      {
        group: 'ggg_loss_prevention_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1tk7fYzNntSz-BfwKebuh3oSaT0qxZ2LIYeifpa1zCtE',
      },
      {
        group: 'ggg_commercial_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1Ez255TEvyBgO5vhQ3kGYX1vRpoN37zXbggXs0vJSF3Y',
      },
      {
        group: 'ggg_marketing_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1FgxSG1lhJZZyOJz8BTVuAuKbySxyI9Z7MXidOM3kQ_8',
      },
      {
        group:
          'man-my-working-pattern-contact-centre-ug@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1TNhD6iSEuLP0x0Y6FCUoXJaX9H-Uj6g5cinUWUGBpZU',
      },
      {
        group:
          'ggg_corporate_services_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1W4O8zFxL3VIrMMzUIj0SixROrdPfHuxaETWo0RHP4lU',
      },
      {
        group: 'man-my-working-pattern-directorate-ug@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1HKltsFQjUWuOj8VneVDGRDJZ8l2iVDfcEoPLJpY7yuc',
      },
      {
        group: 'ggg_finance_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1TuwoibWGI-kflce9VbPrtc5_s-4r3ByuoKXRgxCVHXA',
      },
      {
        group: 'ggg_online_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1YYQ-ZNwSMvLk13ZwTSRe_ovSs-vC3bL9J6FfUQl2bSM',
      },
      {
        group: 'ggg_procurement_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1lRokDIG2OqljJQhY5Ht2aKA6b8n7vk2Mk0HUoTxmgD4',
      },
      {
        group:
          'ggg_productivity_operations_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1yOBFrxoJI-Yw_gteYrlmnVNf8u23cKxSNXaXds4zoG8',
      },
      {
        group: 'ggg_supply_chain_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1ZyMlPR-bmwaervE4RkqU2AHcOREXUG0kw-LRbQ5g6kQ',
      },
      {
        group: 'ggg_property_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1H7CMLcd7wJbKfcF55JzOG7KqZOOAvCupBp37F5vMMZ0',
      },
      {
        group: 'man-my-working-pattern-services-ug@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/19QZcG9VsjPAc7ry8evsFb7F_cbD4XzdZMJ7IM4w2oso',
      },
      {
        group: 'ggg_shared_services_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1lAIV7eqxSKkZathUQgn-rAgAN4CAbsYcHCdF9r9qbm8',
      },
      {
        group: 'ggg_wholesale_central_colleagues@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1Rrt2MVbLya-z-8mq9CbtteD8_Z9l8dzlYtEIKPl4RJo',
      },
      {
        group:
          'man-my-working-pattern---coalville-nutmeg-ug@test.morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/182MeWR4F7Y8aHePWT0XK8YbjiDPvMojcqil8NS5U_M0',
      },
    ],
    yourSaySurveyLink: 'https://dev.workl.co/saml-morrisons',
    yourSayDashboard: 'https://dev.business.workl.co/saml-morrisons',
    empcenterMyScheduleLink:
      'https://morrisons-test.wta-eu3.wfs.cloud/workforce/Desktop.do',
    covid19Link: 'https://my-morri-pre.appspot.com/sites/covid-19',
    diamondsFormLink: 'https://forms.gle/VSGg1RT7myxWZDvw8',
    morrisonsControlTower: {
      morrisonsControlTower : 'https://morrisonscontroltower.uat.testapps.mymorri.com',
      userGuide : 'https://docs.google.com/presentation/d/1_dgBjStO_oFeWt2v-qzYZmM1BS9ku8KBFb6fquhaSFU/edit?usp=sharing'
    },
  },
  prod: {
    epayslips_url: 'https://morrisons-epayslips.appspot.com',
    activity_planner_url:
      'https://morrisons-activity-planner.appspot.com/quarter',
    user_provisioning_url: 'https://morrisons-user-provisioning.appspot.com',
    client_id:
      '1073181092632-cq1jm2k776jb8cr0ghc07qel434pod6q.apps.googleusercontent.com',
    email_domain: 'morrisonsplc.co.uk',
    leavers_group: 'morrisons.leavers@morrisonsplc.co.uk',
    payslips_group: 'morrisons.payslips@morrisonsplc.co.uk',
    thinkShrink_group: 'man-mymorri-shrink-tile-ug@morrisonsplc.co.uk',
    salaried_group: 'ggg_salaried_colleagues@morrisonsplc.co.uk',
    hourly_group: 'ggg_hourly_colleagues@morrisonsplc.co.uk',
    store_group: 'ggg_store_colleagues@morrisonsplc.co.uk',
    central_group: 'ggg_central_colleagues@morrisonsplc.co.uk',
    central_hilmore_group: 'ggg_hilmore_central_colleagues@morrisonsplc.co.uk',
    central_field_group: 'ggg_field_central_colleagues@morrisonsplc.co.uk',
    activityPlanner_super_admin_group:
      'activity.planner.admin.super@morrisonsplc.co.uk',
    skipton_colleague_group: 'ggg_056_colleagues@morrisonsplc.co.uk',
    performSalaried:
      'https://sites.google.com/morrisonsplc.co.uk/performance/salaried-colleagues',
    performHourly:
      'https://sites.google.com/morrisonsplc.co.uk/performance/hourly-paid-colleagues',
    performGeneric: 'https://sites.google.com/morrisonsplc.co.uk/performance/',
    reflexisLink: 'https://morrisons.reflexisinc.co.uk/RWS4',

    holidaysApp:
      'https://sites.google.com/a/morrisonsplc.co.uk/morrison-news/my-rotas-and-my-holidays',

    holidaysAppLink:
      'https://script.google.com/a/morrisonsplc.co.uk/macros/s/AKfycbwZ7DsrDY-6Ch7JFXLf6Pov7yR0LTd_Pyu_ALWohxgSoRdM2VCE/exec',
    communitiesLink: 'http://chat.google.com/',
    handbookDefaultLink:
      'https://sites.google.com/morrisonsplc.co.uk/myhandbook-retail/home',
    leavers_questionnaire:
      'https://docs.google.com/forms/d/e/1FAIpQLSfhGZCj0vBioUtH_Q2TxGun4lnC_1gBoIxu0SZUqwQ1DuBfaQ/viewform?usp=sf_link',
    dataProtectionLink:
      'https://sites.google.com/morrisonsplc.co.uk/data-protection-2019/home',

    colleagueDiscountManagementLink:
      'https://discount.colleague.mymorri.com/home',
    colleagueDiscountRegistrationLink: 'https://discount.mymorri.com',

    google_analytics_id: 'G-8ZSNXHLXV2',
    twoStepLink: 'https://g.co/2sv',
    contentfullApi: {
      space: 'fwof4kybqy1w',
      environment: 'master',
      accessToken: ' ',
      host: window.location.hostname,
      basePath: '/api/contentful',
    },
    retailCommsContentfulApi: {
      space: 'lzjg12b015yu',
      environment: 'master',
      accessToken: ' ',
      host: window.location.hostname,
      basePath: '/api/contentful',
    },
    archive: {
      lastFinancialYear: {
        year: '2020-2021',
        startWeek: '2020-02-03T00:00:00.000Z',
        endWeek: '2021-01-31T06:08:04.000Z',
      },
      years: [
        {
          year: '2021/2022',
          startWeek: '2021-02-01T00:00:00.000Z',
          endWeek: '2022-01-30T06:08:04.000Z',
        },
        {
          year: '2020/2021',
          startWeek: '2020-02-03T00:00:00.000Z',
          endWeek: '2021-01-31T06:08:04.000Z',
        },
        {
          year: '2019/2020',
          startWeek: '2019-02-04T00:00:00.000Z',
          endWeek: '2020-02-02T06:08:04.000Z',
        },
      ],
    },
    handbookLinkRetailNPS:
      'https://sites.google.com/morrisonsplc.co.uk/my-handbook-retail-2-nps',
    handbook: [
      {
        group: 'ggg_store_colleagues@morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/my-handbook-retail-2-nps/home',
      },
      {
        group: 'ggg_manufacturing_colleagues@morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/myhandbook-manufacturing/home',
      },
      {
        group: 'ggg_central_colleagues@morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/myhandbook-hilmorehouse/home',
      },
      {
        group: 'ggg_swanvalley_distribution_colleagues@morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/my-handbook-swanvalley/home',
      },
      {
        group: 'ggg_stockton_distribution_colleagues@morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/myhandbook-stockton/home',
      },
      {
        group: 'ggg_sttngbrne_distribution_colleagues@morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/my-handbook-sittingbourne/home',
      },
      {
        group: 'ggg_latimer_distribution_colleagues@morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/my-handbook-latimerpark-corby/home',
      },
      {
        group: 'ggg_corby_distribution_colleagues@morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/my-handbook-latimerpark-corby/home',
      },
      {
        group: 'ggg_j41_distribution_colleagues@morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/myhandbook-wakefieldj41/home',
      },
      {
        group: 'ggg_gadbrook_distribution_colleagues@morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/my-handbook-gadbrook/home',
      },
      {
        group: 'ggg_gadbrookl_distribution_colleagues@morrisonsplc.co.uk',
        link:
          'https://sites.google.com/morrisonsplc.co.uk/my-handbook-gadbrook/home',
      },
      {
        group: 'morrisons.leavers@morrisonsplc.co.uk',
        link:
        'https://pepl-leavershb-prd-fb.firebaseapp.com',
      },
      {
        group: 'ggg_bridgwater_distribution_colleagues@morrisonsplc.co.uk',
        link: 'https://sites.google.com/morrisonsplc.co.uk/willowgreen1/home',
      },
    ],
    workingPattern: [
      {
        group: 'ggg_people_central_colleagues@morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1t6caE7MJJ7W1prCOS3G3U_mQwrxy_EQOwAfm28TsB8o',
      },
      {
        group: 'ggg_technology_central_colleagues@morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1qqMHXhWI6ovRXYipHWzqLQfTN95e_rg5Ixe8aN_2rUA/edit?usp=sharing',
      },
      {
        group: 'man-myworkingpattern-centrallogistics-ug@morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1WAAqJQ_y0NOxujFfkPHjkivzsUv-OBhKoO-yEyyOiuM',
      },
      {
        group:
          'man-my-working-pattern---central-manufacturing-ug@morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1Ob5qf2uwQTiYRzfkfaBZ0JurowCvnY0cDJbnciiGFjo',
      },
      {
        group: 'ggg_retail_central_colleagues@morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1YjtfekcoHH2EvmnfhVh7zuxy1HBxAT3L0FnSNu-8Kt8',
      },
      {
        group: 'ggg_loss_prevention_central_colleagues@morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1tk7fYzNntSz-BfwKebuh3oSaT0qxZ2LIYeifpa1zCtE',
      },
      {
        group: 'ggg_commercial_central_colleagues@morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1Ez255TEvyBgO5vhQ3kGYX1vRpoN37zXbggXs0vJSF3Y',
      },
      {
        group: 'ggg_marketing_central_colleagues@morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1FgxSG1lhJZZyOJz8BTVuAuKbySxyI9Z7MXidOM3kQ_8',
      },
      {
        group: 'man-my-working-pattern-contact-centre-ug@morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1TNhD6iSEuLP0x0Y6FCUoXJaX9H-Uj6g5cinUWUGBpZU',
      },
      {
        group: 'ggg_corporate_services_central_colleagues@morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1W4O8zFxL3VIrMMzUIj0SixROrdPfHuxaETWo0RHP4lU',
      },
      {
        group: 'man-my-working-pattern-directorate-ug@morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1HKltsFQjUWuOj8VneVDGRDJZ8l2iVDfcEoPLJpY7yuc',
      },
      {
        group: 'ggg_finance_central_colleagues@morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1TuwoibWGI-kflce9VbPrtc5_s-4r3ByuoKXRgxCVHXA',
      },
      {
        group: 'ggg_online_central_colleagues@morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1YYQ-ZNwSMvLk13ZwTSRe_ovSs-vC3bL9J6FfUQl2bSM',
      },
      {
        group: 'ggg_procurement_central_colleagues@morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1lRokDIG2OqljJQhY5Ht2aKA6b8n7vk2Mk0HUoTxmgD4',
      },
      {
        group:
          'ggg_productivity_operations_central_colleagues@morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1yOBFrxoJI-Yw_gteYrlmnVNf8u23cKxSNXaXds4zoG8',
      },
      {
        group: 'ggg_supply_chain_central_colleagues@morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1ZyMlPR-bmwaervE4RkqU2AHcOREXUG0kw-LRbQ5g6kQ',
      },
      {
        group: 'ggg_property_central_colleagues@morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1H7CMLcd7wJbKfcF55JzOG7KqZOOAvCupBp37F5vMMZ0',
      },
      {
        group: 'man-my-working-pattern-services-ug@morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/19QZcG9VsjPAc7ry8evsFb7F_cbD4XzdZMJ7IM4w2oso',
      },
      {
        group: 'ggg_shared_services_central_colleagues@morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1lAIV7eqxSKkZathUQgn-rAgAN4CAbsYcHCdF9r9qbm8',
      },
      {
        group: 'ggg_wholesale_central_colleagues@morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/1Rrt2MVbLya-z-8mq9CbtteD8_Z9l8dzlYtEIKPl4RJo',
      },
      {
        group:
          'man-my-working-pattern---coalville-nutmeg-ug@morrisonsplc.co.uk',
        link:
          'https://docs.google.com/spreadsheets/d/182MeWR4F7Y8aHePWT0XK8YbjiDPvMojcqil8NS5U_M0',
      },
    ],
    yourSaySurveyLink: 'https://workl.co/saml-morrisons',
    yourSayDashboard: 'https://sites.google.com/morrisonsplc.co.uk/your-say-dashboard/home',
    empcenterMyScheduleLink:
      'https://morrisons.wta-eu3.wfs.cloud/workforce/Desktop.do',
    covid19Link: 'https://www.mymorri.com/sites/covid-19',
    diamondsFormLink: 'https://forms.gle/VSGg1RT7myxWZDvw8',
    morrisonsControlTower: {
      morrisonsControlTower : 'https://morrisonscontroltower.apps.mymorri.com',
      userGuide : 'https://docs.google.com/presentation/d/1_dgBjStO_oFeWt2v-qzYZmM1BS9ku8KBFb6fquhaSFU/edit?usp=sharing'
    },
  },
}[process.env.REACT_APP_ENV]
