import React, { useEffect } from 'react'
import ReactGA from 'react-ga4'
import { useLocation } from 'react-router-dom'
import logo from 'images/mymorri_logo.svg'

const AccountErrorPage = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: pathname, title: "Account Error" })
  }, [])

  return (
    <div
      id="error_page"
      className="flex flex-col items-center justify-center h-screen bg-green"
    >
      <div className="relative flex flex-col items-center justify-center p-4">
        <div className="h-32 mb-8">
          <img
            alt="Morrisons MyMorri"
            className="block w-auto h-full mx-auto"
            src={logo}
          />
        </div>
        <div className="text-lg text-center text-orange">
          Oops! There seems to be a problem with your account.
        </div>
        <div className="mt-4 text-lg text-center text-white">
          <p className="pb-4">
            For help with MyMorri, you can call the MyMorri Helpline on{' '}
            <span className="whitespace-no-wrap">0330 041 5587</span>
          </p>
          <p>The Helpline is open 24 hours a day, 365 days a year.</p>
        </div>
      </div>
    </div>
  )
}

export default AccountErrorPage
