import React, { useState, useEffect } from 'react'
import TileBox from './TileBox'
import { Link } from 'react-router-dom'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

const TileWithMenu = ({ svg, text, menu, disable, onClick, open }) => {
  const [showSubMenu, setShowSubMenu] = useState(null)

  useEffect(
    () => {
      setShowSubMenu(open)
    },
    [open]
  )

  const handleClick = event => {
    setShowSubMenu(event.currentTarget)
  }

  const handleClose = () => {
    setShowSubMenu(null)
  }

  const isExternal = url => /^(http|https):\/\//gm.test(url)

  return (
    <div className="relative w-full">
      <div className="w-full" onClick={handleClick}>
        <TileBox svg={svg} text={null} disable={disable} />
        <div className="absolute" style={{ top: '10px', right: '20px' }}>
          <MoreHorizIcon size="small" sx={{ color: '#ffffff' }} />
        </div>
      </div>

      <Menu
        id="simple-menu"
        anchorEl={showSubMenu}
        keepMounted
        open={Boolean(showSubMenu)}
        onClose={handleClose}
        anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
      >
        {(menu || []).map(item => (
          <MenuItem
            onClick={handleClose}
            className={`${
              item.disable ? 'bg-grey-darker cursor-not-allowed' : ''
            } p-0 flex items-center text-left`}
            key={item.id}
          >
            {item.disable ? (
              <span className="w-full text-sm text-grey-light inline-flex h-10 items-center px-3 ">
                {item.text}
              </span>
            ) : isExternal(item.url) ? (
              <a
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
                className="w-full text-sm text-black no-underline hover:text-primary-dark"
                onClick={onClick}
              >
                <span className="w-full inline-flex h-10 items-center px-3">
                  {item.text}
                </span>
              </a>
            ) : (
              <Link
                to={item.url}
                className="w-full text-sm text-black no-underline"
                onClick={onClick}
              >
                <span className="w-full inline-flex h-10 items-center px-3">
                  {item.text}
                </span>
              </Link>
            )}
          </MenuItem>
        ))}
      </Menu>
      <div className="flex items-center">
        <span
          className={` block px-2 py-2 font-light text-center text-black lg:py-4 mob:text-sm w-full leading-tight`}
        >
          {text}
        </span>
      </div>
    </div>
  )
}

export default TileWithMenu
