import MyLearning from 'assets/icons/svg/my-learning.svg'
// import DataProtection from 'assets/icons/svg/data-protection.svg'

export const services = [
  {
    category: 'Tools & apps',
    categoryId: 'tools-apps',
    tile: [
      {
        id: 'promotions',
        svg: MyLearning,
        text: 'Promotions',
        url: 'https://docs.google.com/document/d/19qJq_zT0Zg66rFc5L3C3itz8phZm6_tmOp2SrgVXCPI/edit?usp=sharing',
        disable: false,
        menu: [
          // {
          //   id: 'loading-a-promotion',
          //   text: 'Loading a promotion',
          //   url: 'https://www.mymorri.com/loadingpromotion',
          //   disable: false,
          // },
          // {
          //   id: 'amending-a-promotion',
          //   text: 'Amending a promotion',
          //   url: 'https://www.mymorri.com/amendingpromotion',
          //   disable: false,
          // },
        ],
      },
      {
        id: 'product-supplier-set-up',
        svg: MyLearning,
        text: 'Product / Supplier Set-up',
        url: '',
        disable: false,
        menu: [
          {
            id: 'new-line-forms',
            text: 'New Line forms',
            url: '',
            disable: false,
          },
          {
            id: 'supplier-set-up',
            text: 'Supplier set-up',
            url: '',
            disable: false,
          },
        ],
      },
      {
        id: 'retail-price-change',
        svg: MyLearning,
        text: 'Retail Price Change',
        url: '',
        disable: true,
        menu: [],
      },
    ],
  },
  {
    category: 'Information & FAQs',
    categoryId: 'information-faqs',
    tile: [
      {
        id: 'cost-price-change',
        svg: MyLearning,
        text: 'Cost Price Change',
        url: '',
        disable: false,
        menu: [],
      },
      {
        id: 'booking-supplier-media',
        svg: MyLearning,
        text: 'Booking Supplier Media',
        url: '',
        disable: false,
        menu: [],
      },
      {
        id: 'product-database',
        svg: MyLearning,
        text: 'My Product Profile',
        url: 'https://comm-mpp-prd.web.app/',
        disable: false,
        menu: [
          {
            id: 'my-product-profile',
            text: 'My Product Profile',
            url: 'https://comm-mpp-prd.web.app/',
            disable: false,
          },
          {
            id: ' my-product-profile-training-guide',
            text: 'My Product Profile Training Guide',
            url:
              'https://docs.google.com/presentation/d/1fsM-e2V2UB1mU5Y6uS-ZLV4eoAuQ5OXdgt8IDiKGUy0/edit#slide=id.g13d6f56c71f_1_1086',
            disable: false,
          },
        ],
      },
    ],
  },
  {
    category: 'Reports',
    categoryId: 'reports',
    tile: [
      {
        id: 'how-do-i',
        svg: MyLearning,
        text: 'How Do I',
        url: '',
        disable: false,
        menu: [],
      },
      {
        id: 'performance-reporting',
        svg: MyLearning,
        text: 'Performance Reporting',
        url: '',
        disable: false,
        menu: [
          {
            id: 'sales-buyers-dashboard',
            text: 'Sales: Buyers dashboard',
            url: '',
            disable: false,
          },
          {
            id: 'margin-buyers-dashboard',
            text: 'Margin: Buyers dashboard',
            url: '',
            disable: false,
          },
          {
            id: 'availability-buyers-dashboard',
            text: 'Availability: Buyers dashboard',
            url: '',
            disable: false,
          },
          {
            id: 'budget-control-buyers-dashboard',
            text: 'Budget control: Buyers dashboard',
            url: '',
            disable: false,
          },
        ],
      },
      {
        id: 'midas',
        svg: MyLearning,
        text: 'Midas',
        url: '',
        disable: false,
        menu: [],
      },
      {
        id: 'nominal-ledger-report',
        svg: MyLearning,
        text: 'Nominal Ledger / Report',
        url: '',
        disable: false,
        menu: [
          {
            id: 'invoicing-crediting',
            text: 'Invoicing / crediting',
            url: '',
            disable: false,
          },
          {
            id: 'invoice-agreement',
            text: 'Invoice agreement',
            url: '',
            disable: false,
          },
        ],
      },
    ],
  },
  {
    category: 'Templates & forms',
    categoryId: 'templates-forms',
    tile: [
      {
        id: 'range-change',
        svg: MyLearning,
        text: 'Range Change',
        url: '',
        disable: true,
        menu: [],
      },
      {
        id: 'legal-dispute-tracker',
        svg: MyLearning,
        text: 'Legal Dispute Tracker',
        url:
          'https://docs.google.com/forms/d/12ImF8NX4KHEkHQR-ZOB35NHeJlP8k7fb7M_ETmz9poA/viewform?edit_requested=true',
        disable: false,
        menu: [],
      },
    ],
  },
]
