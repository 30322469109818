import React from 'react'
import { Container } from 'components/utils'

const IdentityBanner = ({ title }) => {
  return (
    <header className="relative py-8 mb-6 md:py-10 bg-gradient-coronavirus">
      <Container>
        <div className="flex">
          <div>
            <h1 className="text-3xl font-semibold text-white md:text-4xl lg:text-4xl">
              {title}
            </h1>
          </div>
        </div>
      </Container>
    </header>
  )
}

export default IdentityBanner
