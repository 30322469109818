import moment from 'moment'
import { compareAsc, differenceInWeeks, getYear, startOfWeek } from "date-fns";

const roundCurrentDate = () => {
  const start = moment(new Date())
  const remainder = 5 - (start.minute() % 5)
  const dateTime = moment(start)
    .add(remainder, 'minutes')
    .format('YYYY-MM-DDTkk:mmZ')
  return dateTime
}

const getWeekDates = (date, weekIncrement = 0) => {
  const currentDate = moment.utc(date);
  currentDate.isoWeeks(currentDate.isoWeeks() + weekIncrement);
  return {
    start: currentDate.startOf("isoWeek").toISOString(),
    weekDate: currentDate.format('YYYY-MM-DD'),
    end: currentDate.endOf("isoWeek").toISOString()
  };
}

const getMondayOfTheWeek = (date) => startOfWeek(date, {
  weekStartsOn: 1
}); 

const getWeekNumber = selected => {
  const selectedDate = new Date(selected);
  const selectedDateYear = getYear(selectedDate);
  let mondayOfFYWeek = getMondayOfTheWeek(new Date(`${selectedDateYear}-11-01`));
  // If FY comes after the selected date, chose the previous FY
  if (compareAsc(mondayOfFYWeek, selectedDate) > 0) {
    mondayOfFYWeek = getMondayOfTheWeek(new Date(`${selectedDateYear - 1}-11-01`));
  }
  const mondayOfThisWeek = getMondayOfTheWeek(selectedDate)
  const weeks = differenceInWeeks(mondayOfThisWeek, mondayOfFYWeek) + 1;
  return weeks
}

/* Method to find the next n items in array */
export const findNext = (array, start, n) => {
  const length = array.length
  let endIndex = start + n
  if (endIndex > length) {
    const itemsAtEnd = array.slice(start, length)
    endIndex -= length
    if (endIndex > start) endIndex = start
    const itemAtStart = array.slice(0, endIndex)
    return [...itemsAtEnd, ...itemAtStart]
  } else {
    return array.slice(start, endIndex)
  }
}

export const saveToken = token => {
  try {
    sessionStorage.setItem('token', token)
  } catch (err) {
    console.error('Error setting session storage')
  }
}

export const getToken = () => {
  try {
    return sessionStorage.getItem('token')
  } catch (err) {
    console.error('Error getting session storage')
  }
}

export const redirectOnError = error => {
  if (error && error.response && error.response.status === 403) {
    window.location = '/timeout'
    return
  } else {
    console.log(error)
    // window.location = '/error'
  }
}

export default {
  getWeekDates,
  getWeekNumber,
  roundCurrentDate: () => roundCurrentDate(),
  findNext,
  saveToken,
  getToken,
  redirectOnError,
}
