import React, { useEffect, useState } from 'react'
import { TheHeader, HeaderTab } from 'components/header'
import { SearchContainer } from 'components/search'
import ServiceList from 'components/portal/commercialtrading/ServiceList'
import { useUserState } from 'store/user-context'
import { useNewsState, useNewsDispatch } from 'store/news-context'
import ReactGA from 'react-ga4'
import { useLocation } from 'react-router-dom'

const CommercialHomePage = () => {
  const userState = useUserState()
  const current_user = userState.current_user
  const newsState = useNewsState()
  const { hideSearchContainer } = useNewsDispatch()
  const [openAccordion, setOpenAccordion] = useState(false)
  const { pathname } = useLocation()

  useEffect(() => {
    hideSearchContainer()
    const loadFirst = localStorage.getItem('loadFirst')
    if (!loadFirst) {
      localStorage.setItem('loadFirst', true)
      setOpenAccordion(true)
    }
    ReactGA.send({ hitType: "pageview", page: pathname, title: "Commercial Home" })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      {current_user.error ? (
        <div
          id="main_content"
          className="flex flex-col items-center justify-center"
        >
          Error retrieving your details
        </div>
      ) : (
        <div id="main_content" className={`list-page `}>
          <HeaderTab activeTab="commercialhomepage" />
          <TheHeader />
          <section className="bg-grey-transparent">
            <div className="container mx-auto md:px-8">
              <SearchContainer isActive={newsState.isSearchActive} />
            </div>
          </section>
          <ServiceList openAcc={openAccordion} />
        </div>
      )}
    </div>
  )
}

export default CommercialHomePage
