import React, { useEffect } from 'react'
import { TheHeader, HeaderTab } from 'components/header'
import { SearchContainer } from 'components/search'
import ServiceListByCategory from 'components/portal/commercialtrading/ServiceListByCategory'
import { useUserState } from 'store/user-context'
import { useNewsState, useNewsDispatch } from 'store/news-context'

const CommercialTradingByCategory = () => {
  const userState = useUserState()
  const current_user = userState.current_user
  const newsState = useNewsState()
  const { hideSearchContainer } = useNewsDispatch()

  useEffect(() => {
    hideSearchContainer()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      {current_user.error ? (
        <div
          id="main_content"
          className="flex flex-col items-center justify-center"
        >
          Error retrieving your details
        </div>
      ) : (
        <div id="main_content" className={`list-page `}>
          <HeaderTab activeTab="commercialtrading" />
          <TheHeader />
          <section className="bg-grey-transparent">
            <div className="container mx-auto md:px-8">
              <SearchContainer isActive={newsState.isSearchActive} />
            </div>
          </section>
          <ServiceListByCategory />
        </div>
      )}
    </div>
  )
}

export default CommercialTradingByCategory
