import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import LandingPage from './pages/landing-page'
import SearchList from './pages/search-list'
import Timeout from './pages/timeout'
import Login from './pages/login'
import ErrorPage from './pages/error'
import AccountErrorPage from './pages/account-error'
import Info from './pages/info'
import MyRota from './pages/my-rota'
import { TheSideBar } from './components/sidebar'
import Sites from './pages/sites'
import Pages from './pages/pages'
import NewsDetails from './pages/news-details'
import CommercialHomePage from './pages/portal/commercial-trading'
import CommercialTradingByCategory from './pages/portal/commercial-trading-by-category'

import { useUserState } from 'store/user-context'

function authy ( Component  ) {
  const state = useUserState()
  return state.initial_load_complete ? <Component/>  : <Login />
}

const AppRoute = () => {
  return (
    <div className="min-h-screen">
      <TheSideBar />
      <Router>
      <Routes>
         <Route path="/search" element={authy(SearchList)} />
          <Route path="/info" element={<Info/>} />
          <Route path="/error" element={<ErrorPage/>} />
          <Route path="/account-error" element={<AccountErrorPage/>} />
          <Route path="/timeout" element={<Timeout/>} />
          <Route path="/myrota" element={<MyRota/>} />
          <Route
            exact
            path="/portal/commercialhomepage"
            element={authy(CommercialHomePage)}
          />
          <Route
            exact
            path="/portal/commercialtradingbycategory"
            element={authy(CommercialTradingByCategory)}
          />
          <Route exact path="/sites" element={authy(Sites)} />
          <Route
            path="/sites/:siteName?/:rootPage?/:sub1?/:sub2?"
            element={authy(Pages)}
          />
          <Route path="/news/:id" element={authy(NewsDetails)} />
          <Route path="/" exact element={authy(LandingPage)} /> 
       
      </Routes>
      </Router>
    </div>
  )
}

export default AppRoute
