import React, { useState, useEffect } from 'react'
import Tile from 'components/portal/Tile/Tile'
import IdentityBanner from 'components/portal/IdentityBanner'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { services } from './services'
const ServiceList = ({ openAcc }) => {
    const [selectedTile, setSelectedTile] = useState(null)
    const [openAccordion, setOpenAccordion] = useState(openAcc)
    const handleClick = id => {
        setSelectedTile(id)
    }

    useEffect(
        () => {
            setOpenAccordion(openAcc)
        },
        [openAcc]
    )

    return (
        <>
            <IdentityBanner title="Commercial Homepage" />
            <section className="container mx-auto md:px-8">
                <Accordion
                    expanded={openAccordion}
                    onChange={() => {
                        setOpenAccordion(!openAccordion)
                    }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="about-content"
                        id="about-header"
                    >
                        <Typography>About the Commercial Homepage</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div>
                            <p className="mb-4">
                                This is the Commercial homepage, the central hub for all your
                                daily Commercial needs. Use this homepage to access your most
                                important business tasks, such as:
              </p>
                            <ul className="font-light">
                                <li>accessing commercial reports through the reporting app</li>
                                <li>
                                    accessing process or policy information through the ‘How Do
                                    I?’ app
                </li>
                                <li>
                                    starting a trading process e.g. Setting up a New Supplier, New
                                    Line, Booking supplier Media or Promotions.
                </li>
                            </ul>
                            <p className="mt-4">
                                Apps shown in green are available for you to use; apps shown in
                                grey are in development as part of the How Commercial Works
                                programme: whenever a new Commercial solution is completed and
                                made available to you, the app will turn from grey to green.
              </p>
                            <p className="mt-4">
                                Note that you will only be able to enter an app if you have
                                already been given access rights by the associated system
                                administrator.
              </p>
                            <p className="mt-4">
                                If you cannot access an App that you believe you should be able
                                to then please mention this to your manager.
              </p>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <div
                    data-cy="icon-group"
                    className="flex flex-wrap mx-6 my-4 md:mb-8 lg:-mx-2"
                >
                    {services.map(item => (
                        <Tile
                            {...{ ...item, onClick: handleClick, key: item.id, selectedTile }}
                        />
                    ))}
                </div>
            </section>
        </>
    )
}

export default ServiceList
