import React, { useState } from 'react'
import Tile from 'components/portal/Tile/Tile'
import IdentityBanner from 'components/portal/IdentityBanner'

import { services } from './services-by-category'
const ServiceListByCategory = () => {
  const [selectedTile, setSelectedTile] = useState(null)
  const handleClick = id => {
    setSelectedTile(id)
  }
  return (
    <>
      <IdentityBanner title="Commercial Homepage" />
      <section className="container mx-auto md:px-8">
        {services.map(item => (
          <div key={item.categoryId}>
            <h2>{item.category}</h2>
            <div
              data-cy="icon-group"
              className="flex flex-wrap mx-6 my-4 md:mb-8 lg:-mx-2"
            >
              {item.tile.map(tile => (
                <Tile
                  {...{
                    ...tile,
                    onClick: handleClick,
                    key: tile.id,
                    selectedTile,
                  }}
                />
              ))}
            </div>
          </div>
        ))}
      </section>
    </>
  )
}

export default ServiceListByCategory
