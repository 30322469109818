import React, { useEffect } from 'react'
import { HttpClient, Core } from '../util'
import { useNavigate } from 'react-router-dom'
import logo from 'images/mymorri_logo.svg'
import CircularProgress from 'components/utils/CircularProgress'
import config from 'config'
import ReactGA from 'react-ga4'
import contentfulClient from 'util/contentful-client'
import { useUserDispatch } from 'store/user-context'
import { useNewsDispatch } from 'store/news-context'
import { useSetState } from '../util'

const Login = () => {
  const defaultState = {
    display: 'block',
    visibility: 'hidden',
    show_spinner: false,
    signinFailure: false,
  }
  const [state, setState] = useSetState(defaultState)
  const saveUser = useUserDispatch()
  // const { setUnviewedNotifications } = useNewsDispatch()
  const navigate = useNavigate()

  const initiateGoogleLogin = () => {
    const googleClientId = config.client_id
    window.google.accounts.id.initialize({
        client_id: googleClientId,
        auto_select:true,
        callback: onSuccess
    });
    window.google.accounts.id.renderButton(
        document.getElementById("my-signin2"),
        { theme: "outline", size: "large" }  // customization attributes
    );
    window.google.accounts.id.prompt(); // also display the One Tap dialog
  
  }
  
  useEffect(() => {
    let timer
    if (config.stub_oauth) {
      const googleUser = { credential: 'OAUTHTOKEN'}
      onSuccess(googleUser)
    } else {
      initiateGoogleLogin()
    }

    timer = setTimeout(() => setState({ visibility: 'visible' }), 500)

    return () => {
      clearTimeout(timer)
    }
    /* eslint-disable-next-line */
  }, [])

  const onSuccess = googleUser => {
    setState({ display: 'none', show_spinner: true })
    const userData = HttpClient.post('/api/users/login', {
      google_auth_token: googleUser.credential,
    }).then(data => {
      if (data.account_error) {
        navigate('/account-error')
        return
      } else {
        ReactGA.event({
          category: 'Navigation',
          action: 'Signin',
          label: data.store_name + ' (' + data.store_id + ')',
        })
        contentfulClient.createClient(data)
        Core.saveToken(data.token)
        saveUser({
          current_user: data,
          token: data.token,
          initial_load_complete: true,
        })
        // setUnviewedNotifications(data)
        return data.employee_id
      }
    })

    Promise.all([userData])
  }

  const loginButtonWrapper = {
    width: '280px',
  }
  const spinnerWrapper = {
    transform: 'translateY(-20px)',
  }
  return (
    <div
      id="main_content"
      className="flex flex-col items-center justify-center h-screen bg-green"
    >
      <div className="relative flex flex-col items-center justify-center">
        <div className="h-32 mb-8">
          <img
            alt="Morrisons MyMorri"
            className="block w-auto h-full mx-auto"
            src={logo}
          />
        </div>
        {!state.signinFailure && (
          <div className="flex justify-center h-16" style={loginButtonWrapper}>
            <div
              id="my-signin2"
              style={{
                display: state.display,
                visibility: state.visibility,
              }}
            />
            {state.show_spinner ? (
              <div style={spinnerWrapper}>
                <CircularProgress size={40} thickness={4} />
              </div>
            ) : null}
          </div>
        )}
        {state.signinFailure ? (
          <div className="text-center">
            <div className="mt-4 text-xl text-orange">
              There was an error signing in, please try again.
            </div>
            <div className="mt-4 text-center text-white text-md">
              <p className="pb-4">
                For help with MyMorri, you can call the MyMorri Helpline on 0330
                041 5587
              </p>
              <p>The Helpline is open 24 hours a day, 365 days a year.</p>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default Login
