import React from 'react'
import {
  TileBox,
  TileWithExternalLink,
  TileWithInternalLink,
  TileWithMenu,
} from './'

const Tile = ({ id, svg, text, url, disable, menu, onClick, selectedTile }) => {
  const isExternal = /^(http|https):\/\//gm.test(url)
  const handleClick = e => {
    onClick()
  }

  const handleTileClick = id => {
    onClick(id)
  }

  return (
    <div
      id={id}
      data-cy={id}
      className="relative flex w-1/3 px-1 sm:w-1/5 md:w-1/6 lg:w-1/8 lg:px-2 lg:mb-0"
    >
      {menu.length > 0 ? (
        <TileWithMenu
          onClick={handleTileClick}
          menu={menu}
          svg={svg}
          text={text}
          disable={disable}
          open={selectedTile === id}
        />
      ) : disable ? (
        <div className="w-full">
          <TileBox svg={svg} text={text} disable={disable} />
        </div>
      ) : isExternal ? (
        <TileWithExternalLink
          onClick={handleClick}
          url={url}
          svg={svg}
          text={text}
          disable={disable}
        />
      ) : (
        <TileWithInternalLink
          onClick={handleClick}
          url={url}
          svg={svg}
          text={text}
          disable={disable}
        />
      )}
    </div>
  )
}

export default Tile
