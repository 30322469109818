import MyLearning from 'assets/icons/svg/my-learning.svg'
import DataProtection from 'assets/icons/svg/data-protection.svg'
import MyPerformance from 'assets/icons/svg/my-performance.svg'
import OurSafeLegal from 'assets/icons/svg/our-safe-legal.svg'
import MyPension from 'assets/icons/svg/my-pension.svg'
import MyExpenses from 'assets/icons/svg/my-expenses.svg'
import MyHandbook from 'assets/icons/svg/my-handbook.svg'
import Leavers from 'assets/icons/svg/leavers.svg'
import MyInfo from 'assets/icons/svg/my-info.svg'
import Generic from 'assets/icons/svg/generic.svg'
import MyTeam from 'assets/icons/svg/my-team.svg'
import PeopleSystemGuide from 'assets/icons/svg/people-system-guide.svg'
import ActivityPlanner from 'assets/icons/svg/activity-planner.svg'
import BarcodeCheckDigits from 'assets/icons/svg/barcodecheckdigits.svg'

export const services = [
  {
    id: 'range-change',
    svg: Leavers,
    text: 'Range Change',
    url: '',
    disable: false,
    menu: [
      {
        id: 'range-change-app',
        text: 'Reset app',
        url: 'https://rangeresetapp.apps.mymorri.com/login',
        disable: false,
      },
      {
        id: 'range-change-guide',
        text: 'Range Reset User Guide',
        url:
          'https://docs.google.com/presentation/d/1dDh2ZLoarsOO97DdlLfxRbiE3Q8lPT37YZhfUxumgSk/edit?ts=5ff46d4a#slide=id.g126abe4231_2_326',
        disable: false,
      },
      {
        id: 'range-change-commercial-reports',
        text: 'Range Reset - Commercial Reports',
        url:
          'https://morrisons-qs.catalysthost.cloud/sense/app/4440da93-f1a6-47d4-9f3f-e0ab99b686f2/overview',
        disable: false,
      },
    ],
  },
  {
    id: 'my-product',
    svg: MyLearning,
    text: 'myProduct',
    url: 'https://sites.google.com/morrisonsplc.co.uk/myproduct',
    disable: false,
    menu: [],
  },
  {
    id: 'legal-dispute-tracker',
    svg: OurSafeLegal,
    text: 'Legal Dispute Tracker',
    url:
      'https://docs.google.com/forms/d/12ImF8NX4KHEkHQR-ZOB35NHeJlP8k7fb7M_ETmz9poA/viewform?edit_requested=true',
    disable: false,
    menu: [],
  },
  {
    id: 'performance-reporting',
    svg: MyPerformance,
    text: 'Performance Reporting',
    url:
      'https://morrisons-qs.catalysthost.cloud/sense/app/6801b60a-c1ab-41e4-9b48-0f0270795f9e/sheet/1808ccc0-1ace-4273-8f02-0e077638b424/state/analysis',
    disable: false,
    menu: [],
  },
  {
    id: 'gscop-docs',
    svg: PeopleSystemGuide,
    text: 'GSCOP Ways of Working Policies',
    url:
      'https://docs.google.com/document/d/1gYzDgwY9-ikZV6dSV4TnPOusB7bpRy5e1ZgF9oUEyqs/edit?usp=sharing',
    disable: false,
    menu: [],
  },
  {
    id: 'food-trends',
    svg: MyHandbook,
    text: 'Food Trends',
    url:
      'https://drive.google.com/drive/folders/1CqbUBg_NxNKHnLvH2bdXPx2s7fBmlaoQ',
    disable: false,
    menu: [],
  },
  {
    id: 'stock-withdrawal',
    svg: ActivityPlanner,
    text: 'Stock Withdrawal',
    url: '',
    disable: false,
    menu: [
      {
        id: 'stock-withdrawal-salesforce',
        text: 'Salesforce',
        url: 'https://login.salesforce.com/',
        disable: false,
      },
      {
        id: 'stock-withdrawal-manage',
        text: 'How I manage withdrawals',
        url:
          'https://docs.google.com/presentation/d/1Wr3m9aZDEdZRIP3j6zUwKhRCdEqSkt3ZGM_EdE2aLuI/edit?usp=drive_web',
        disable: false,
      },
      {
        id: 'stock-withdrawal-recover',
        text: 'How I recover cost',
        url:
          'https://docs.google.com/presentation/d/1WgFeXH_C0kQMIKNo_k6q5X_sNjKvTZ0E25FtHJnQfwc/edit?usp=drive_web',
        disable: false,
      },
      {
        id: 'stock-withdrawal-guide-for-buyers',
        text: 'Withdrawal Guide for Buyers',
        url:
          'https://docs.google.com/document/d/1Q3_0dlF7_EF7IxBPlqllv7kMZpEEFphHtrSAuFtaSnY/edit?usp=sharing',
        disable: false,
      },
    ],
  },
  {
    id: 'how-do-i',
    svg: MyInfo,
    text: 'How Do I',
    url: 'https://sites.google.com/morrisonsplc.co.uk/howdoi/',
    disable: false,
    menu: [],
  },
  {
    id: 'product-database',
    svg: DataProtection,
    text: 'My Product Profile',
    url: 'https://comm-mpp-prd.web.app/',
    disable: false,
    menu: [
      {
        id: 'my-product-profile',
        text: 'My Product Profile',
        url: 'https://comm-mpp-prd.web.app/',
        disable: false,
      },
      {
        id: ' my-product-profile-training-guide',
        text: 'My Product Profile Training Guide',
        url:
          'https://docs.google.com/presentation/d/1fsM-e2V2UB1mU5Y6uS-ZLV4eoAuQ5OXdgt8IDiKGUy0/edit#slide=id.g13d6f56c71f_1_1086',
        disable: false,
      },
    ]
  },
  {
    id: 'promotions',
    svg: MyPension,
    text: 'Promotions',
    url: 'https://docs.google.com/document/d/19qJq_zT0Zg66rFc5L3C3itz8phZm6_tmOp2SrgVXCPI/edit?usp=sharing',
    disable: false,
    menu: [],
    // menu: [
    //   {
    //     id: 'loading-a-promotion',
    //     text: 'Loading a promotion',
    //     url: '',
    //     disable: true,
    //   },
    //   {
    //     id: 'amending-a-promotion',
    //     text: 'Amending a promotion',
    //     url: '',
    //     disable: true,
    //   },
    // ],
  },
  {
    id: 'barcodecheckdigits',
    svg: BarcodeCheckDigits,
    text: 'Barcode Check Digits',
    url: 'https://barcodevalidator.apps.mymorri.com',
    disable: false,
    menu: [],
  },
  {
    id: 'cost-price-change',
    svg: MyExpenses,
    text: 'Cost Price Change',
    url: '',
    disable: false,
    menu: [
      {
        id: 'cost-price-change-guide-buyers',
        text: 'Cost Price Change Guide: Buyers',
        url: 'https://drive.google.com/file/d/1OXf7lO3_pxTEru5QCSivAH-VciP1sG2q/view?usp=drive_link',
        disable: false,
      },
      {
        id: 'price-change-guide-suppliers',
        text: 'Price Change Guide: Suppliers',
        url: 'https://drive.google.com/file/d/1s8faXU_bx533TOsX_W6lxBks5yfPE-0_/view?usp=drive_link',
        disable: false,
      },
      {
        id: 'cost-price-change-template ',
        text: 'Cost Price Change Template',
        url: 'https://docs.google.com/spreadsheets/d/18pVs37bi0USp6MGF14z8mw7rrxRyv3cK/edit?usp=drive_link&ouid=111835754884583468486&rtpof=true&sd=true',
        disable: false,
      },
    ],
  },
  {
    id: 'midas',
    svg: Generic,
    text: ' Overriders/Midas',
    url: 'https://docs.google.com/presentation/d/11Hgr2HJpoAOUpcad8mgGgGt0HDPP5u6Sozm51G65A7g/edit#slide=id.g24d34f8ed2a_0_0',
    disable: false,
    menu: [],
  },
  {
    id: 'product-supplier-set-up',
    svg: MyTeam,
    text: 'Product / Supplier Set-up',
    url: '',
    disable: true,
    menu: [
      {
        id: 'new-line-forms',
        text: 'New Line forms',
        url: '',
        disable: true,
      },
      {
        id: 'supplier-set-up',
        text: 'Supplier set-up',
        url: '',
        disable: true,
      },
    ],
  },
  {
    id: 'booking-supplier-media',
    svg: Generic,
    text: 'Booking Supplier Media',
    url: '',
    disable: true,
    menu: [],
  },
  
  {
    id: 'retail-price-change',
    svg: Generic,
    text: 'Retail Price Change',
    url: '',
    disable: true,
    menu: [],
  },
  {
    id: 'commodity-report',
    svg: Leavers,
    text: 'Commodity Report',
    url: '',
    disable: true,
    menu: [],
  },
  {
    id: 'nominal-ledger-report',
    svg: MyHandbook,
    text: 'Nominal Ledger / Report',
    url: '',
    disable: true,
    menu: [
      {
        id: 'invoicing-crediting',
        text: 'Invoicing / crediting',
        url: '',
        disable: true,
      },
      {
        id: 'invoice-agreement',
        text: 'Invoice agreement',
        url: '',
        disable: true,
      },
    ],
  },
  {
    id: 'supplier-forecast',
    svg: OurSafeLegal,
    text: 'Supplier Forecast',
    url: '',
    disable: true,
    menu: [],
  },
  {
    id: 'trends-insights',
    svg: OurSafeLegal,
    text: 'Trends & Insights',
    url: '',
    disable: true,
    menu: [],
  },
]
