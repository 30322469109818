import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import 'react-tabs/style/react-tabs.css'
import { LinerProgressBar } from 'components/sites/LinerProgressBar'


import { renderBanner } from 'util/retail-utils'
import { RetailMainNav } from './MainNavigation'
import Archive from './Archive'
import RetailContent from './RetailContent'
import { contentfulClient, Core } from '../../util'
import { checkDate, tabTitles } from '../../util/retail-utils';

export default function RetailCommsTabs() {
  const navigate = useNavigate()
  const { rootPage, sub1 } = useParams()
  const [loading, setLoading] = useState(false)
  const [archive, setArchive] = useState(null)
  const [week, setWeek] = useState(null)
  const [tabDates, setTabDates] = useState(null);
  const [bulletinType, setBulletinType] = useState(null);
  const [bulletinTypes, setBulletinTypes] = useState(null);

  const onBulletinTypeClick = bulletinType => {
    navigate(`/sites/retail-comms/${week}/${bulletinType?.slug}`);
    setBulletinType(bulletinType);
  };

  const onNavClick = date => {
    navigate(`/sites/retail-comms/${date}/${bulletinType?.slug}`);
    if (date === "archive") {
      setArchive(true);
      setWeek(null);
    } else {
      setArchive(false);
      setWeek(date);
    }
  };

  useEffect(
    () => {
      const tabDates = [];
      for (let i = 0; i < tabTitles.length - 1; i++) {
        const dates = Core.getWeekDates(new Date(), i - 1);
        tabDates.push(dates.weekDate);
      }
      setTabDates(tabDates);
    }, []
  );

  useEffect(
    () => {
      if (tabDates) {
        if (rootPage === 'archive') {
          setWeek(null);
          setArchive(true);
        } else {
          setArchive(false);
          const date = checkDate(rootPage);
          setWeek(date === null 
            ? tabDates[1]
            : date
          );
        }
      }
    }, [rootPage, tabDates, week]
  );

  useEffect(
    () => {
      if (bulletinTypes === null) {
        setLoading(true);

        contentfulClient
          .getReatilCommsContentfulClient()
          .getEntries({
            content_type: 'bulletinType',
            order: 'fields.order'
          })
          .then(entries => {
            const types = [];
            let type = null;
            let currentType = null;
            for (let i = 0; i < entries.items.length; i++) {
              type = {...entries.items[i].fields, id: entries.items[i].sys.id};
              types.push(type);
              if (!sub1 && type.order === 1) {
                currentType = type;
              } else if (sub1 && sub1 === type.slug) {
                currentType = type;
              }
            }
            setBulletinType(currentType);
            setBulletinTypes(types);
            setLoading(false);
          })
          .catch(error => {
            setLoading(false);
            console.log(error)
          })
      }
    },
    [bulletinTypes, setBulletinType, setBulletinTypes, setLoading, sub1]
  )

  useEffect(
    () => {
      if (bulletinTypes && bulletinTypes.length) {
        setBulletinType(bulletinTypes[0]);
        if (sub1) {
          setBulletinType(bulletinTypes.find(type => type.slug === sub1));
        }
      }
    },
    [bulletinTypes, setBulletinType, sub1]
  )

  if (!week && !archive) {
    return "";
  }

  const titleIndex = tabDates.indexOf(week);

  return (
    <>
      <RetailMainNav
        onClick={onNavClick}
        tabDates={tabDates}
        week={week}
      />
      {renderBanner(tabTitles[titleIndex > -1 ? titleIndex : 5], week)}
      {archive ? (
        <Archive
          onClick={onNavClick}
        />
      ) : (
        <RetailContent
          bulletinType={bulletinType}
          bulletinTypes={bulletinTypes}
          onBulletinTypeClick={onBulletinTypeClick}
          week={week}
        />
      )}
      {loading && <LinerProgressBar />}
    </>
  )
}
