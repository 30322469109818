import React, { createContext, useReducer, useContext } from 'react'

const UserStateContext = createContext()
const UserDispatchContext = createContext()

const initialState = {
  current_user: {},
  token: null,
  initial_load_complete: false,
}

function UserProvider({ children }) {
  const [state, saveUser] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    initialState
  )

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={saveUser}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  )
}

function useUserState() {
  const context = useContext(UserStateContext)
  if (context === undefined) {
    throw new Error('UserState must be used within a UserProvider')
  }
  return context
}

function useUserDispatch() {
  const context = useContext(UserDispatchContext)
  if (context === undefined) {
    throw new Error('UserDispatch must be used within a UserProvider')
  }
  return context
}

export { UserProvider, useUserState, useUserDispatch }
